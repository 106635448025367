import React, { useEffect, useState } from "react";
import useClinicIncomes from "../../redux/customHook/useClinicIncome";
import usePatientCount from "../../redux/customHook/usePatientCount";
import TodayReport from "./report/todayReport";
import MonthalyReport from "./report/monthalyReport";
import { useDispatch } from "react-redux";
import Spinner from "../loading/spinner";
import StatsCard from "./patientCount";
import IncomeStats from "./incomeStats";
import useData from "../../redux/customHook/useData";
import apiServices from "../../../Service/apiService";
import CustomReport from "./customReport";

const PatientTally = () => {
  const dispatch = useDispatch();
  const [tabType, setTabType] = useState("income");
  const [dayTabType, setDayTabType] = useState("day7Report");
  const { clinicIncomes, anotherIncome } = useClinicIncomes();
  const { patientVisit, monthVisit } = usePatientCount();
  const [result, setResult] = useState("");
  const [dayResult, setDayResult] = useState("");
  const [customReport, setCustomReport] = useState(false)

  const [loadinSpinner, setLoadingSpinner] = useState(false);

  const [clinic, setClinic] = useState("");
  const { data } = useData();
  useEffect(() => {
    if (data && data?.clinicDtos) {
      setClinic(data.clinicDtos);
    }
  }, [data]);
  useEffect(() => {
    if(dayTabType == "YearReport"){
      setResult([])
      handleClinicModal();
    }else{
      setResult([])
      handleDayClinicModal();
    }  
  }, [clinic, dayTabType])
  
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const handleDayClinicModal = async () => {
    const token = sessionStorage.getItem("token");
    let clinicId = 0;
    let clinicLocation = "";
    let allResults = [];
    for (let i = 0; i < clinic.length; i++) {
      clinicId = clinic[i].id;
      clinicLocation = clinic[i].location;
      try {
        const response = await apiServices.get(
          "authentication",
          "/payment/weeklyGraphByClinicId",
          {
            Authorization: "Bearer " + token,
          },
          {
            clinicId: clinicId,
          },
          null
        );

        if (response.statusCode === 200) {
          allResults.push({ clinicId, clinicLocation, ...response.data });
        }
      } catch (error) {
        if (error.response.data.statusCode === 404) {
          console.log(error);
        }
      }
    }
    setResult(allResults);
  };
  const handleClinicModal = async () => {
    const token = sessionStorage.getItem("token");
    let clinicId = 0;
    let clinicLocation = "";
    let allResults = [];
    for (let i = 0; i < clinic.length; i++) {
      clinicId = clinic[i].id;
      clinicLocation = clinic[i].location;
      try {
        const response = await apiServices.get(
          "authentication",
          "/payment/yearlyGraphByClinicId",
          {
            Authorization: "Bearer " + token,
          },
          {
            year: year,
            clinicId: clinicId,
          },
          null
        );

        if (response.statusCode === 200) {
          allResults.push({ clinicId, clinicLocation, ...response.data });
        }
      } catch (error) {
        if (error.response.data.statusCode === 404) {
          console.log(error);
        }
      }
    }
    setResult(allResults);
  };
  useEffect(() => {
    // Check if all data is loaded
    if (
      clinicIncomes &&
      anotherIncome &&
      patientVisit !== null &&
      monthVisit !== null
    ) {
      setLoadingSpinner(false);
    }
  }, [clinicIncomes, anotherIncome, patientVisit, monthVisit]);
  return (
    <>
      <section className="text-gray-600 body-font">
        <div className="container max-w-6xl px-5 py-10 mx-auto">
          <div className="flex flex-wrap -mx-2">
            <div className="px-2 pl-10 w-full md:w-1/4">
              <StatsCard title="Today's Visits" value={patientVisit || 0} />
            </div>
            {clinicIncomes && (
              <div className="pl-10 px-2 w-full md:w-3/4">
                <IncomeStats incomeData={clinicIncomes} />
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font">
        <div className="container max-w-6xl px-5 pb-10 mx-auto">
          {anotherIncome && (
            <div className="flex flex-wrap -mx-2">
              <div className="px-2 pl-10 w-full md:w-1/4">
                <StatsCard
                  title="Past 30 Day's Visits"
                  value={monthVisit || 0}
                />
              </div>
              <div className="pl-10 px-2 w-full md:w-3/4">
                <IncomeStats incomeData={anotherIncome} />
              </div>
            </div>
          )}
        </div>
      </section>
     
     
      <div className="flex flex-wrap max-w-screen-xl mx-auto">
  <div className="flex flex-col border rounded-lg w-screen h-1/2 px-10 py-8 text-gray-700">
    {/* Row for Centered "1 day" and "30 day" buttons and Right-aligned buttons */}
    <div className="flex justify-between items-center mb-4 w-full">
      {/* Centered "1 day" and "30 day" buttons */}
      <div className="flex justify-center w-full space-x-4">
        <button
          type="button"
          onClick={() => {
            setDayTabType("day7Report");
          }}
          className={`py-2.5 md:pl-60  pl-0 px-5 mb-2 ${dayTabType == "day7Report" ? "underline text-red-600" :"text-red-800"} text-sm font-medium  focus:outline-none focus:z-10`}
        >
          7 day
        </button>

        <button
          type="button"
          onClick={() => {
            setDayTabType("YearReport");
          }}
          className={`py-2.5 px-5 pr-5 mb-2  ${dayTabType == "YearReport" ? "underline text-red-800" :"text-red-600"} text-sm font-medium `}
        >
          1 Year
        </button>
      </div>

      {/* Right-aligned "Total Income" and "Patient Visits" buttons */}
      <div className="flex md:ml-auto items-center flex-shrink-0 space-x-4">
        <button
          type="button"
          onClick={() => {
            setTabType("income");
          }}
          className={`py-2 px-2 mb-2 text-sm font-medium rounded-lg ${
            tabType === "income"
              ? "text-white border-red-700 bg-red-700"
              : "text-black bg-gray-200 border-gray-400"
          } focus:outline-none border focus:z-10`}
        >
          Total Income
        </button>

        <button
          type="button"
          onClick={() => {
            setTabType("visit");
          }}
          className={`py-2 px-2 mb-2 text-sm font-medium rounded-lg ${
            tabType === "visit"
              ? "text-white border-red-700 bg-red-700"
              : "text-black bg-gray-200 border-gray-400"
          } focus:outline-none border focus:z-10`}
        >
          Patient Visits
        </button>
      </div>
    </div>

    {/* Conditional rendering for reports */}
    {tabType === "income" ? (
      <TodayReport result={result} />
    ) : (
      <MonthalyReport result={result} />
    )}

    {/* Custom Report Button */}
    <div className="flex justify-end mt-4">
      <p
        className="py-2.5 px-5 cursor-pointer mb-10 rounded-md text-sm font-medium text-white bg-red-800 focus:outline-none focus:z-10"
        onClick={() => {
          setCustomReport(true);
        }}
      >
        Custom Report
      </p>
    </div>
  </div>
</div>



      {loadinSpinner && <Spinner />}
      {customReport && < CustomReport reset={()=>{setCustomReport(false)}} />}
    </>
  );
};

export default PatientTally;
