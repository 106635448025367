import React, { useState, useEffect, useRef } from "react";
import apiServices from "../../../Service/apiService";
import { load } from "@cashfreepayments/cashfree-js";
import useData from "../../redux/customHook/useData";
import SubscriptionModal from "./subscriptionModal";
import { formatDateToDDMMYY } from "../../specialMethod/getBetweenDate";
import Spinner from "../loading/spinner";
import Invoice from "./invoice";

const Subscription = () => {
  const [doctorDetails, setDoctorDetails] = useState([]);
  const [payment, setPayment] = useState(false);
  const [history, setHistory] = useState("");
  const [invoice, setInvoice] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState("");
  const cashfreeRef = useRef(null);
  const { data } = useData();

  const [state, setState] = useState({
    loadinSpinner: true,
  });
  const toggleState = (key, value) =>
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  // Payment related information
  useEffect(() => {
    const initializeSDK = async () => {
      try {
        const cashfree = await load({
          mode: "production",
        });
        cashfreeRef.current = cashfree;
      } catch (error) {
        console.error("Error is coming from initialize payment ", error);
      }
    };
    initializeSDK();
  }, []);
  // Doctor Details information
  useEffect(() => {
    if (data) {
      setDoctorDetails(data || []);
      handlePayment();
    }
  }, [data]);
  const handlePayment = async () => {
    toggleState("loadinSpinner", true);
    const token = sessionStorage.getItem("token");
    try {
      const response = await apiServices.get(
        "authentication",
        "/subscriptionPaymentHistory/allSubscriptionPaymentHistory",
        {
          Authorization: " Bearer " + token,
        },
        {
          doctorId: data.id,
        },
        null
      );
      if (response) {
        setHistory(response.data);
        console.log(response);
        toggleState("loadinSpinner", false);
      }
    } catch (error) {
      console.error("error is coming from api", error);
    } finally {
      toggleState("loadinSpinner", false);
    }
  };
  const getFullName = doctorDetails.firstName + " " + doctorDetails.lastName;

  const handleCheckoutFunction = () => {
    setPayment(true);
  };

  const showInvoice = (hist) => {
    setInvoice(true);
    setInvoiceDetails(hist);
  };


  return (
    <>
      <div className="flex flex-wrap items-center justify-between  max-w-screen-xl  mx-auto">
        <div className="flex flex-col  ">
          {/* first card */}
          <div className="flex flex-col p-6 transition-all duration-500 bg-white border border-indigo-100 rounded-lg shadow hover:shadow-xl lg:p-5 lg:flex-row lg:space-y-0 lg:space-x-6">
            <div className="flex-1">
              <h5 className="mb-1 text-lg font-medium lg:text-1xl">
                Choose Subscription{" "}
              </h5>
              <p className="mb-3 text-sm font-bold text-gray-600">
                Your monthly Subscription amount is Rs. 1500/month
              </p>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={handleCheckoutFunction}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* table section */}
        <div className="overflow-x-auto">
          <table className="w-full divide-y border-2 rounded-lg border-gray-400  divide-gray-200 overflow-x-auto">
            <thead class="border-b-2 border-gray-400">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Start Date
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  End Date
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  COST
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Invoice
                </th>
              </tr>
            </thead>
            {history && (
              <tbody className="bg-white divide-y divide-gray-200">
                {history.map((hist, index) => (
                  <tr className="border rounded-full ">
                    <td className="px-6 py-4 whitespace-nowrap ">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {hist.subscriptionStartDate}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {hist.subscriptionEndDate}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      ₹ {hist.amount}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-xs leading-5  ${
                          hist.subscriptionEndDate <
                          formatDateToDDMMYY(new Date())
                            ? "bg-red-100 text-red-800"
                            : "bg-green-100 text-green-800"
                        }  font-semibold rounded-full  text-green-800`}
                      >
                        {hist.subscriptionEndDate <
                        formatDateToDDMMYY(new Date())
                          ? "expired"
                          : "Active"}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button
                        type="button"
                        onClick={() => {
                          showInvoice(hist);
                        }}
                        className="text-red-800 underline"
                      >
                        {" "}
                        View Invoice
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
      {payment && (
        <SubscriptionModal
          value={() => {
            setPayment(false);
          }}
          data={data}
        />
      )}
      {state.loadinSpinner && <Spinner />}
      {invoice && (
        <Invoice
          reset={() => setInvoice(false)}
          invoiceDetails={invoiceDetails}
        />
      )}
    </>
  );
};

export default Subscription;
