import React, { useEffect, useState } from "react";
import NavbarWrapper from "../../../widgets/navbarWrapper";
import AlertClinicModal from "./alertClinicModal";
import useData from "../../redux/customHook/useData";
import Spinner from "../loading/spinner";
import { useNavigate } from "react-router-dom";
import EditClinic from "./editClinic";
import apiServices from "../../../Service/apiService";
import { useDispatch } from "react-redux";
import { deleteClinicSuccess } from "../../redux/features/data/dataSlice";
import DeleteClinic from "./deleteClinic";

const Clinic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    alertMessage: false,
    loadingSpinner: true,
    getClinicDetails: [],
    editClinic: false,
    selectedClinicDetails: null,
    clinicDelete: false,
    clinicDeleteId: "",
  });

  const toggleState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const { data } = useData();

  useEffect(() => {
    if (data) {
      toggleState("loadingSpinner", false);
      const activeClinics = data.clinicDtos.filter(
        (clinic) => clinic.clinicStatus === "Active"
      );
      toggleState("getClinicDetails", activeClinics || []);
    }
  }, [data]);

  const updatedClinics = (newClinic) => {
    setState((prevState) => ({
      ...prevState,
      getClinicDetails: [...prevState.getClinicDetails, newClinic],
    }));
  };

  // Function to convert full day name to single-letter abbreviationzz
  const getShortDayName = (fullDayName) => {
    const daysMap = {
      SUNDAY: "S",
      MONDAY: "M",
      TUESDAY: "T",
      WEDNESDAY: "W",
      THURSDAY: "Th",
      FRIDAY: "F",
      SATURDAY: "Sa",
    };
    return daysMap[fullDayName.toUpperCase()];
  };

  // Function to generate UI markup for displaying opening days with single-letter abbreviations
  const renderOpeningDays = (clinicDays) => {
    return clinicDays.map((fullDayName) => (
      <span
        key={fullDayName}
        style={{
          marginRight: "0.5em",
          color: clinicDays.includes(fullDayName) ? "red" : "inherit",
        }}
      >
        {getShortDayName(fullDayName)}
      </span>
    ));
  };
  const handleCheckAppointments = (clinicId) => {
    navigate(`/appointment/${clinicId}`);
  };

  return (
    <>
      <NavbarWrapper showNavbar={true} />
      {/* header section */}
      <div className="bg-white border-gray-200 py-4 dark:bg-white ">
        <div className="flex flex-wrap md:mt-20 mt-0 items-center justify-between max-w-screen-xl px-4 mx-auto">
          <p className="text-2xl font-bold text-gray-900 montserrat">
            Location
          </p>

          <div className="flex items-center lg:order-2">
            <button
              onClick={() => {
                toggleState("alertMessage", true);
              }}
              className="text-white hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-6 focus:outline-none dark:focus:ring-purple-800 dark:hover:text-black"
              style={{ backgroundColor: "rgb(251,146,31)" }}
            >
              Add Clinic
            </button>
            {state.alertMessage && (
              <AlertClinicModal
                value={() => {
                  toggleState("alertMessage", false);
                }}
                updatedClinics={updatedClinics}
                data={data}
              />
            )}
          </div>
        </div>

        {/* List of clinic */}
        <div className="container px-4 md:px-8 flex justify-center ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {state.getClinicDetails.length === 0 ? (
              <div className="text-gray-800 text-center montserrat">
                No Clinic Found !
              </div>
            ) : (
              state.getClinicDetails.map((value, index) => (
                <div
                  key={index}
                  className="max-w-md bg-white dark:bg-white border-gray-100 border-2 rounded-xl overflow-hidden shadow-lg mt-4"
                >
                  <div className="px-4 pb-12">
                    <div className="py-2 my-1">
                      {/* <h3
                        className="font-bold text-xl mb-1 montserrat"
                        style={{ color: "rgb(251,146,31)" }}
                      >
                    
                      </h3> */}
                      <div className="text-gray-700 dark:text-gray-800 flex justify-between items-center">
                        <h3
                          className="font-bold text-xl mb-1 montserrat"
                          style={{ color: "rgb(251,146,31)" }}
                        >
                          {value.clinicName}
                        </h3>
                        <span
                          className="text-black cursor-pointer"
                          onClick={() => {
                            toggleState("clinicDelete", true);
                            toggleState("clinicDeleteId", value.id);
                          }}
                        >
                          <svg
                            className="w-6 h-6 text-red-500 fill-current"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9 2a1 1 0 011 1v1h6a1 1 0 110 2h-1v11a2 2 0 01-2 2H7a2 2 0 01-2-2V6H4a1 1 0 110-2h6V3a1 1 0 011-1zM7 6v11h6V6H7zm2-3v1h2V3H9z" />
                          </svg>
                        </span>
                      </div>
                      <div className="pb- flex">
                        <svg
                          className="h-5 w-5 text-gray-600 dark:text-red-500 mr-1 inline-block"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path
                            className=""
                            d="M5.64 16.36a9 9 0 1 1 12.72 0l-5.65 5.66a1 1 0 0 1-1.42 0l-5.65-5.66zm11.31-1.41a7 7 0 1 0-9.9 0L12 19.9l4.95-4.95zM12 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                          />
                        </svg>
                        <h3 className="font-semibold text-sm text-gray-800 dark:text-gray-800 mb-1 montserrat">
                          {value.location}
                        </h3>
                      </div>
                      <div className="text-gray-700 dark:text-gray-800 flex justify-between items-center">
                        <p className="text-black text-md font-semibold">
                          Visiting New Patient Fees
                        </p>
                        <span className="text-black">
                          ₹ {value.clinicNewFees}
                        </span>
                      </div>
                      <div className="text-gray-700 dark:text-gray-800 flex justify-between items-center">
                        <p className="text-black text-md font-semibold">
                          Visiting Old Patient Fees
                        </p>
                        <span className="text-black">
                          ₹ {value.clinicOldFees}
                        </span>
                      </div>

                      <div className="pb-3">
                        <h3 className="font-semibold text-sm text-gray-800 dark:text-gray-800 mb-1 montserrat">
                          Compounder: {value.incharge}
                        </h3>
                        <h3 className="font-semibold text-sm text-gray-800 dark:text-gray-800 mb-1 montserrat">
                          Phone No: +91 {value.clinicContact}
                        </h3>
                      </div>
                      <div className="flex justify-between items-center">
                        <h3 className="font-semibold text-sm text-gray-800 dark:text-gray-800 mb-1 montserrat">
                          Time: {value.startTime.substring(0, 5)} -{" "}
                          {value.endTime.substring(0, 5)}
                        </h3>
                        <div className="mr-4">
                          {value.days ? (
                            <div className="flex font-semibold items-center">
                              {renderOpeningDays(value.days)}
                            </div>
                          ) : (
                            <span>No opening days</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-1 px-1">
                      <button
                        className="rounded-full bg-blue-600 dark:bg-gray-100 text-gray-800 dark:text-gray-300 dark:hover:text-gray-500 antialiased font-semibold px-4 py-2"
                        style={{ backgroundColor: "rgb(235,235,235)" }}
                        onClick={() => {
                          toggleState("editClinic", true);
                          toggleState("selectedClinicDetails", value);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="flex-1 rounded-full bg-blue-600 dark:bg-gray-100 text-white dark:text-white  antialiased font-bold lg:px-20 px-12 py-2"
                        style={{
                          backgroundColor: "rgb(7,160,165)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => handleCheckAppointments(value.id)}
                      >
                        Check Appointments
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {state.loadingSpinner && <Spinner />}

      {state.editClinic && (
        <EditClinic
          clinic={state.selectedClinicDetails}
          reset={() => {
            toggleState("editClinic", false);
          }}
          updatedClinic={updatedClinics}
        />
      )}
      {state.clinicDelete && (
        <DeleteClinic
          clinicId={state.clinicDeleteId}
          reset={() => {
            toggleState("clinicDelete", false);
          }}
        />
      )}
    </>
  );
};

export default Clinic;
