import React, { useState, useEffect } from "react";
import Navbar from "../../../widgets/navbar";
// import Feedback from "./feedback";
import * as Yup from "yup";
import { useFormik } from "formik";
import apiServices from "../../../Service/apiService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useData from "../../redux/customHook/useData";
import Spinner from "../loading/spinner";
const Help = () => {
  const { data } = useData();
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [doctorDetails, setDoctorDetails] = useState([]);

  useEffect(() => {
    if (data) {
      setDoctorDetails(data || []);
    }
  }, [data]);
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      emailFrom: "",
      phoneNumber: "",
      content: "",
    },
    validateOnBlur: false,
    validateOnChanget: false,
    validationSchema: Yup.object().shape({
      content: Yup.string().required("enter your message please"),
    }),
    onSubmit: (values) => {
      handleFeedBackForm(values);
    },
  });
  const handleFeedBackForm = async (values) => {
    setLoadingSpinner(true);
    try {
      const response = await apiServices.post(
        "otpValidation",
        "/feedback",
        null,

        {
          firstName: doctorDetails.firstName,
          lastName: doctorDetails.lastName,
          emailFrom: doctorDetails.email,
          phoneNumber: doctorDetails.contact,
          content: values.content,
        },
        null
      );
      if (response.statusCode === 200) {
        return response;
      }
    } catch (error) {
      console.log("response....", error.response);
    } finally {
      setLoadingSpinner(false);
    }
  };
  return (
    <>
      <Navbar />

      <div className="max-w-screen-lg  mx-auto p-5">
        <div className="grid grid-cols-1 md:mt-20 mt-0 md:grid-cols-12 border">
          <div
            className=" md:col-span-4 p-10 hidden md:block text-black"
            style={{ backgroundColor: "rgb(237,231,242)" }}
          >
            {/* <p className="mt-4 text-sm leading-7 font-regular uppercase">Contact</p> */}
            <p className="text-2xl font-bold text-gray-900 montserrat">
              Get In Touch
            </p>
            <p className="mt-4 leading-7 text-black">
            Flat no. 33 Mangaldeep Apartment
Patliputra Colony, Patna,
800013
            </p>

            <div className="flex items-center mt-5">
              <a href="mailto:enquiry@doc-aid.in" className="text-sm">
                Email: enquiry@doc-aid.in
              </a>
            </div>
            <div className="flex items-center mt-5">
              <span className="text-sm"> Call: 0612-4061095</span>
            </div>
            <div className="flex items-center mt-5"></div>
          </div>
          <form
            action="https://fabform.io/f/{form-id}"
            method="post"
            className="md:col-span-8 p-10"
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                 htmlFor="grid-first-name"
                >
                  First Name
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  name="firstName"
                  value={doctorDetails.firstName}
                  placeholder="FirstName"
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <p style={{ color: "red" }}>{formik.errors.firstName}</p>
                ) : null}
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  Last Name
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  name="lastName"
                  value={doctorDetails.lastName}
                  placeholder="lastName"
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <p style={{ color: "red" }}>{formik.errors.lastName}</p>
                ) : null}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  Email
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  name="emailFrom"
                  value={doctorDetails.email}
                  placeholder="Email"
                />
                {formik.touched.emailFrom && formik.errors.emailFrom ? (
                  <p style={{ color: "red" }}>{formik.errors.emailFrom}</p>
                ) : null}
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  Phone
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="number"
                  name="phoneNumber"
                  value={doctorDetails.contact}
                  placeholder="Phone"
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <p style={{ color: "red" }}>{formik.errors.phoneNumber}</p>
                ) : null}
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Your Message
                </label>
                <textarea
                  rows="10"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="content"
                  value={formik.values.content}
                  onChange={formik.handleChange}
                  placeholder="Your Feedback Message"
                ></textarea>
                {formik.touched.content && formik.errors.content ? (
                  <p style={{ color: "red" }}>{formik.errors.content}</p>
                ) : null}
              </div>
             
              <div className="p-2 w-full">
          <button type="submit" className="flex mx-auto text-white  border-0 py-2 px-8 focus:outline-none hover:bg-green-400  rounded text-lg" style={{ backgroundColor: "rgb(7,160,165)" }}>Send Message</button>
        </div>
            </div>
          </form>
          <div
            className=" md:col-span-4 p-10 text-black md:hidden"
            style={{ backgroundColor: "rgb(237,231,242)" }}
          >
            {/* <p className="mt-4 text-sm leading-7 font-regular uppercase">Contact</p> */}
            <p className="text-2xl font-bold text-gray-900 montserrat">
              Get In Touch
            </p>
            <p className="mt-4 leading-7 text-black">
            Flat no. 33 Mangaldeep Apartment
Patliputra Colony, Patna,
800013
            </p>

            <div className="flex items-center mt-5">
              <a href="mailto:enquiry@doc-aid.in" className="text-sm">
                Email: enquiry@doc-aid.in
              </a>
            </div>
            <div className="flex items-center mt-5">
              <span className="text-sm"> Call: 0612-4061095</span>
            </div>
            <div className="flex items-center mt-5"></div>
          </div>
        </div>
      </div>

      <ToastContainer />
     {loadingSpinner && <Spinner/>}
    </>
  );
};

export default Help;
