import React from 'react'
import { useDispatch } from 'react-redux';
import { deleteClinicSuccess } from '../../redux/features/data/dataSlice';
import apiServices from '../../../Service/apiService';

const DeleteClinic = ({reset, clinicId}) => {
    const dispatch = useDispatch()
    const handleClinicDelete= async (id)=>{
        try {
          const response = await apiServices.patch(
            "authentication",
            `/clinic/delete/${clinicId}`,
            null,
            null,
            null
          );
          if (response) {
            dispatch(deleteClinicSuccess(clinicId));
            console.log("clinic delete")
            reset();
          }
        } catch (error) {
          console.error("error in deleting ", error);
        } 
        console.log(id)
      }
    
  return (
    <div
    id="login-popup"
    tabIndex="-1"
    className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
  >
    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
      <div className="relative bg-white rounded-lg shadow">
        <button
          type="button"
          className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
          onClick={reset}
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="#c6c7c7"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              cliprule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close popup</span>
        </button>
        <div className="p-5">
          {/* <h3 className="text-2xl mb-0.5 font-medium"></h3> */}

          <div className="text-center">
            <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
              Delete Clinic
            </p>
          
          </div>


          <p className="mb-5 text-md font-semibold leading-5 text-slate-900">
             Do you really want to delete clinic?
            </p>

            <div className="grid grid-cols-2 gap-6">

              <div className="col-span-2 sm:col-span-1 ">
              <button
                type="button" onClick={()=>{reset()}}
                className="w-full bg-green-500  text-white font-medium py-3 rounded-lg focus:outline-none"
              >
                No
              </button>
              </div>
              <div className="col-span-2 sm:col-span-1 ">
              <button
                type="submit" onClick={()=>{handleClinicDelete()}}
                className="w-full bg-red-500  text-white font-medium py-3 rounded-lg focus:outline-none"
              >
                Yes
              </button>
              </div>
            </div>  
        </div>
      </div>
    </div>
  </div>
  )
}

export default DeleteClinic