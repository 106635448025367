import React, { useState } from "react";
import NavbarWrapper from "../../../widgets/navbarWrapper";
import PatientTally from "./patientTally";
import Subscription from "./subscription";
const Account = () => {
  const [tabType, setTabType] = useState("PatientTally");

  return (
    <>
      <NavbarWrapper showNavbar={true} />
      <div className="flex flex-wrap  items-center justify-between max-w-screen-xl px-4 py-5 mx-auto">
        <p className="text-2xl font-bold md:mt-20 mt-0 text-gray-900 montserrat">
          Welcome to Your Account
        </p>
        <div className="flex md:mt-20 mt-0">
          <button
            type="button"
            className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-white focus:outline-none bg-white rounded-lg border  hover:bg-gray-100 focus:z-10  focus:ring-gray-200  dark:bg-gray-300 dark:text-white  dark:hover:bg-gray-300"
            style={{ backgroundColor: "rgb(216,69,60)" }}
            onClick={() => {
              setTabType("PatientTally");
            }}
          >
            View Stats
          </button>
          <button
            type="button"
            className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-black focus:outline-none rounded-lg border  border-gray-300   hover:text-blue-700 focus:z-10    dark:bg-gray-800 dark:text-black dark:border-gray-500 dark:hover:text-red-500 dark:focus:ring-gray-700  "
            style={{ backgroundColor: "rgb(235,235,235)" }}
            onClick={() => {
              setTabType("Subscription");
            }}
          >
            Review subscription
          </button>
        </div>
      </div>

      {tabType === "PatientTally" ? <PatientTally /> : <Subscription />}
    </>
  );
};

export default Account;
