import React, { useState, useEffect } from "react";
import "./calendarStyles.css"; // Ensure this is your stylesheet with the above CSS
import useData from "../../redux/customHook/useData";
import Spinner from "../loading/spinner";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomToolbar from "./customToolbar"; // Import the custom toolbar
import "../../../component/../App.css";
import MonthCalendar from "./monthCalendar";
import AddScheduleForDoctor from "./addScheduleForDoctor";
import EditSchedule from "./editSchedule";

const localizer = momentLocalizer(moment);
const styles = {
  wrap: {
    display: "flex",
  },
  left: {
    marginRight: "10px",
  },
  main: {
    flexGrow: "1",
  },
};

const CalendarSchedule = () => {
  const [state, setState] = useState({
    loadingSpinner: false,
    collectScheduleData: [],
    selectedEvent: null,
    modalSalect: false,
    addScheduleButton: false,
  });

  const toggleState = (key, value) => {
    setState((preValues) => ({
      ...preValues,
      [key]: value,
    }));
  };

  const { data } = useData();

  useEffect(() => {
    if (data) {
      toggleState("loadingSpinner", false);
      toggleState("collectScheduleData", data.docIntr || []);
    }
  }, [data]);
  const getRandomColor = (i) => {
    const colorWheelShades = [
      "rgb(250,194,190)", "rgb(255, 178, 102)", 
      "rgb(202,255,255)", "rgb(217,250,250)",
      "rgb(255,229,205)", "rgb(250,233,217)",
      "rgb(250,220,220)", "rgb(250,190,190)",
      "rgb(237,231,242)", "rgb(232,210,250)",
      "rgb(227,231,252)", "rgb(227,231,252)",
    ];
    return colorWheelShades[i%10];
  };

  const updatedSchedule = (newSchedule) => {
    setState((prevState) => ({
      ...prevState,
      collectScheduleData: [...prevState.collectScheduleData, newSchedule],
    }));
  };

  const events = state.collectScheduleData.map((event) => {
    return {
      start: new Date(`${event.stDate}T${event.startTime}`),
      end: new Date(`${event.endDate}T${event.endTime}`),
      id: event.id,
      color: getRandomColor(event.id),
      title: `${event.clinicName}-${event.purpose}`,
      stDate: event.stDate,
      endDate: event.endDate,
      startTime: event.startTime,
      endTime: event.endTime,
      clinicName: event.clinicName,
      purpose: event.purpose,
    };
  });

  const handleEventSelect = (event) => {
    toggleState("selectedEvent", event);
    toggleState("modalSalect", true);
  };

  const closeModal = () => {
    toggleState("modalSalect", false);
    toggleState("selectedEvent", null);
  };

  return (
    <>
      <div style={styles.wrap}>
        <div style={styles.left}></div>
        <div className="App" style={{ padding: "1px" }}>
          <div className="flex flex-row items-start justify-between">
            <div className="md:w-3/8 w-full md:mt-[75px]">
              <MonthCalendar />
              <div className="flex items-center mb-3 my-4 md:mb-10">
                <button
                  onClick={() => {
                    toggleState("addScheduleButton", true);
                  }}
                  className="bg-teal-700 flex text-white px-4 py-2 rounded-md"
                >
                  <img
                    src="https://d2sv8898xch8nu.cloudfront.net/MediaFiles/editLogo.png"
                    alt=""
                    className="mt-1 w-4"
                  />
                  <span className="pl-3"> Edit</span>
                </button>
              </div>
            </div>
            <div className="md:w-5/8 ml-14 w-full hidden md:block">
              <Calendar
                localizer={localizer}
                startAccessor={"start"}
                events={events}
                endAccessor={"end"}
                style={{
                  height: "1000px",
                  width: "800px",
                }}
                eventPropGetter={(event) => {
                  return {
                    style: {
                      backgroundColor: event.color,
                      color: "black",
                      fontFamily: "cambaria",
                    },
                  };
                }}
                onSelectEvent={handleEventSelect}
                views={[Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]}
                components={{
                  toolbar: CustomToolbar,
                }}
              />
            </div>
          </div>
          <div className="w-full md:hidden overflow-x-auto">
            <Calendar
              localizer={localizer}
              startAccessor={"start"}
              events={events}
              endAccessor={"end"}
              style={{
                height: "1000px",
                width: "380px",
              }}
              eventPropGetter={(event) => {
                return {
                  style: {
                    backgroundColor: event.color,
                    color: "black",
                  },
                };
              }}
              onSelectEvent={handleEventSelect}
              views={[Views.WEEK, Views.MONTH, Views.DAY, Views.AGENDA]}
              components={{
                toolbar: CustomToolbar,
              }}
            />
          </div>
        </div>
      </div>

      {state.loadingSpinner && <Spinner />}
      {state.modalSalect && (
        <EditSchedule reset={closeModal} event={state.selectedEvent} />
      )}
      {state.addScheduleButton && (
        <AddScheduleForDoctor
          reset={() => {
            toggleState("addScheduleButton", false);
          }}
          updatedSchedule={updatedSchedule}
        />
      )}
    </>
  );
};

export default CalendarSchedule;
