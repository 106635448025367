import React, { useState } from "react";
import Cropper from "react-easy-crop";

function ImageCropper({ image, onCropCancel, onCropDone }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(3 / 0.75);
  const [imgAfterCrop, setImgAfterCrop] = useState("");
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };
  const handleCancel = () => {
    if (imgAfterCrop === "") {
      console.log("he");
      onCropCancel();
    } else {
      setImgAfterCrop("");
    }
  };

  const onAspectRatioChange = (event) => {
    setAspectRatio(event.target.value);
  };
  const onCropDones = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );

      const dataURL = canvasEle.toDataURL("image/jpeg");

      setImgAfterCrop(dataURL);
    };
  };
  const handleSave = () => {
    if (imgAfterCrop !== "") {
      onCropDone(croppedArea);
      onCropCancel();
    } else {
      onCropDones(croppedArea);
    }
  };
  return (
    <>
      <div
        id="login-popup"
        tabIndex="-1"
        className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
      >
        <div className="h-[80%] md:h-[92%]  overflow-y-auto">
          <div className="relative w-full max-w-md h-full md:h-auto ">
            <div className="relative bg-white rounded-lg shadow">
              <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
                onClick={onCropCancel}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="#c6c7c7"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    cliprule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close popup</span>
              </button>
              <div className="p-5 ">
                <p className="mb-4 text-sm font-normal text-gray-800"></p>

                <div className="text-center">
                  <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
                    Crop Prescription Header
                  </p>
                </div>

                <div className=" my-10">
                  <div className="my-10 py-10 w-[400px]">
                    {imgAfterCrop === "" ? (
                      <Cropper
                        image={image}
                        aspect={aspectRatio}
                        crop={crop}
                        zoom={zoom}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                        style={{
                          containerStyle: {
                            width: "100%",
                            height: "80%",
                            backgroundColor: "#fff",
                          },
                        }}
                      />
                    ) : (
                      <div>
                        <img src={imgAfterCrop} className="cropped-img" />
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-18 pt-8 my-10 ">
                  <div
                    class={`flex justify-center ${
                      imgAfterCrop === "" ? "mt-60" : ""
                    } `}
                  >
                    <button
                      onClick={handleCancel}
                      class="inline-flex text-orange-500 bg-white-500 border py-2 px-6 focus:outline-none bg-white hover:bg-orange-500 hover:text-white border-orange-500  rounded text-lg"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSave}
                      class="ml-4 inline-flex  text-white bg-orange-500 hover:bg-white hover:text-orange-500  border border-orange-500 py-2 px-6 focus:outline-none rounded text-lg"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImageCropper;
