import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import useData from '../../redux/customHook/useData';
import apiServices from '../../../Service/apiService';
import { formatDateToDDMMYY, getStartAndEndDateOfMonth } from '../../specialMethod/getBetweenDate';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import qr from "../../../assets/doc-aid.png";

const CustomReport = ({ reset }) => {
    const { data } = useData();
    const token = sessionStorage.getItem("token");

    const [state, setState] = useState({
        loadingSpinner: false,
        selectClinic: [],
        filteredClinics: [],
        customRange: false
    });

    const formik = useFormik({
        initialValues: {
            clinicId: "",
            Present: "",
            startDate: "",
            endDate: ""
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object({
            clinicId: Yup.string().required("Clinic is required"),
            Present: Yup.string().required("Range is required"),
        }),
        onSubmit: (values) => {
            handleReport(values);
        },
    });

    useEffect(() => {
        if (data) {
            setState(prevState => ({
                ...prevState,
                selectClinic: data || [],
                filteredClinics: data.clinicDtos || []
            }));
        }
    }, [data]);

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            customRange: formik.values.Present === 'custom'
        }));
    }, [formik.values.Present]);

    let allIds = [];

    const handleReport = (values) => {
        if (formik.values.clinicId === 'all') {
            allIds = state.filteredClinics.map(clinic => ({
                id: clinic.id,
                location: clinic.location,
            }));
            console.log("All Clinic Ids", allIds);
        }
        if (values.clinicId === 'all') {
            handleAllClinicReport(values);
        } else {
            if (values.Present === 'month') {
                handleMonthReport(values);
            } else if (values.Present === 'year') {
                handleYearReport(values);
            } else {
                handleCustomRangeReport(values);
            }
        }
    };

    const handleAllClinicReport = async (values) => {
        const { Present } = values;
    
        let allTableBody = []; // To store all table data]
    
        for (let i = 0; i < allIds.length; i++) {
            const clinicId = allIds[i].id;
            const clinicLocation = allIds[i].location;
    
            const reportValues = {
                ...values,
                clinicId,
                clinicLocation,
            };
    
            let response;
            if (Present === 'month') {
                response = await getMonthReport(reportValues);
            } else if (Present === 'year') {
                response = await getYearReport(reportValues);
            } else {
                response = await getCustomRangeReport(reportValues);
            }
    
            // Add each clinic's report data to the combined lists
            if (response) {
                const { tableBody, totalSummar  } = response;
                console.log("sams",tableBody)
                console.log("Sadr",totalSummar)
                if (i > 0) {
                    allTableBody.push([{ content: '', colSpan: 5, styles: { halign: 'center', fillColor: [255, 255, 255] } }]); // Add blank row for spacing
                }
                // Add the clinic location as a header row before each clinic's data
                allTableBody.push([{ content: `Clinic Location: ${clinicLocation}`, colSpan: 5, styles: { halign: 'left', fontStyle: 'bold' } }]);
    
                allTableBody.push(...tableBody);
                if (Present !== 'year') {
                    allTableBody.push(totalSummar)
                }
                
            }
        }
    
        // Generate combined PDF after gathering data for all clinics
        const doc = new jsPDF();
        generatePDF(doc, qr,
            ['Date/Month', 'Total Appointments', 'Total Amount', 'Cash Amount', 'UPI Amount'],
            allTableBody,
            [],
            'All Clinics'
        );
    };
    
    // Helper functions to handle each type of report and return the required data
    const getMonthReport = async ({ clinicId, clinicLocation }) => {
        try {
            const response = await apiServices.get(
                "authentication",
                "/payment/customGraphByClinicId",
                { Authorization: "Bearer " + token },
                { startDate: startDateOfMonth, endDate: endDateOfMonth, clinicId }
            );

            if (response) {
                return formatReportData(response.data);
            }
        } catch (error) {
            console.error('Error fetching monthly report:', error);
        }
    };

    const getYearReport = async ({ clinicId }) => {
        try {
            const response = await apiServices.get(
                "authentication",
                "/payment/yearlyGraphByClinicId",
                null,
                { year: currentYear, clinicId }
            );

            if (response) {
                return formatReportData(response.data);
            }
        } catch (error) {
            console.error('Error fetching yearly report:', error);
        }
    };

    const getCustomRangeReport = async ({ clinicId, startDate, endDate }) => {
        try {
            const response = await apiServices.get(
                "authentication",
                "/payment/customGraphByClinicId",
                { Authorization: "Bearer " + token },
                { startDate, endDate, clinicId }
            );

            if (response) {
                return formatReportData(response.data);
            }
        } catch (error) {
            console.error('Error fetching custom range report:', error);
        }
    };

    // Formats the report data for use in the PDF
    const formatReportData = (reportData) => {
        const totalSummary = reportData['9999-12-31'] || {};
        const tableBody = Object.keys(reportData)
            .filter(date => date !== '9999-12-31')
            .map(date => {
                const dayReport = reportData[date] || {};
                return [
                    date,
                    dayReport.totalAppointments || 0,
                    dayReport.totalAmount || 0,
                    dayReport.cashAmount || 0,
                    dayReport.upiAmount || 0,
                ];
            });
            console.log("s",totalSummary)
        const totalSummar = [
            'Total Summary',
            totalSummary.totalAppointments || 0,
            totalSummary.totalAmount || 0,
            totalSummary.cashAmount || 0,
            totalSummary.upiAmount || 0,
        ];

 console.log("f",totalSummar)
        return { tableBody, totalSummar };
    };

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = getStartAndEndDateOfMonth();
    const startDateOfMonth = formatDateToDDMMYY(currentMonth.startDateOfMonth);
    const endDateOfMonth = formatDateToDDMMYY(currentMonth.endDateOfMonth);

    // Function to generate PDF
    const generatePDF = (doc,  imageSrc, header, body, summary, location) => {
        const img = new Image();
        img.src = imageSrc;
       
        img.onload = () => {
            
            doc.addImage(img, 'JPEG', 150, 6, 48, 16);
            doc.text(`${state.selectClinic.firstName} ${state.selectClinic.lastName}`, 14,10);
            doc.text(`Clinic Location - ${location}`, 14, 19);
            // Main table
            doc.autoTable({ startY: 32, head: [header], body });

            // Summary table
            const finalY = doc.lastAutoTable?.finalY || 50;
            if(summary &&  summary.length >0){
                doc.autoTable({
                    startY: finalY + 10,
                    head: [['Summary', 'Total Appointments', 'Total Amount', 'Cash Amount', 'UPI Amount']],
                    body: summary,
                });
            }
            

            doc.save('report.pdf');
        };
        reset();
    };

    const handleClinicChange = (e) => {
        const clinicId = e.target.value;
        const clinicLocation = e.target.selectedOptions[0].getAttribute("data-location");

        formik.setValues(prevValues => ({
            ...prevValues,
            clinicId,
            clinicLocation,
        }));
    };

    // Inside your CustomReport component

const handleMonthReport = async (values) => {
    const { clinicId } = values;

    try {
        const response = await apiServices.get(
            "authentication",
            "/payment/customGraphByClinicId",
            { Authorization: "Bearer " + token },
            { startDate: startDateOfMonth, endDate: endDateOfMonth, clinicId }
        );

        if (response) {
            const formattedData = formatReportData(response.data);
            console.log("sa",formattedData)
            const doc = new jsPDF();
            generatePDF(
                doc,
                qr,
                ['Date/Month', 'Total Appointments', 'Total Amount', 'Cash Amount', 'UPI Amount'],
                formattedData.tableBody,
                [formattedData.totalSummar],
                values.clinicLocation
            );
        }
    } catch (error) {
        console.error('Error fetching monthly report:', error);
    }
};

const handleYearReport = async (values) => {
    const { clinicId } = values;

    try {
        const response = await apiServices.get(
            "authentication",
            "/payment/yearlyGraphByClinicId",
            null,
            { year: currentYear, clinicId }
        );

        if (response) {
            const formattedData = formatReportData(response.data);
            const doc = new jsPDF();
            generatePDF(
                doc,
                qr,
                ['Month', 'Total Appointments', 'Total Amount', 'Cash Amount', 'UPI Amount'],
                formattedData.tableBody,
                [formattedData.totalSummar],
                values.clinicLocation
            );
        }
    } catch (error) {
        console.error('Error fetching yearly report:', error);
    }
};

const handleCustomRangeReport = async (values) => {
    const { clinicId, startDate, endDate } = values;

    try {
        const response = await apiServices.get(
            "authentication",
            "/payment/customGraphByClinicId",
            { Authorization: "Bearer " + token },
            { startDate, endDate, clinicId }
        );

        if (response) {
            const formattedData = formatReportData(response.data);
            const doc = new jsPDF();
            generatePDF(
                doc,
                qr,
                ['Date', 'Total Appointments', 'Total Amount', 'Cash Amount', 'UPI Amount'],
                formattedData.tableBody,
                [formattedData.totalSummar],
                values.clinicLocation
            );
        }
    } catch (error) {
        console.error('Error fetching custom range report:', error);
    }
};


    return (
        <div
            id="login-popup"
            tabIndex="-1"
            className="bg-black/50 fixed top-0 right-0 left-0 z-50 h-full flex items-center justify-center"
        >
            <div className="relative p-4 w-full max-w-xl">
                <div className="relative bg-white rounded-lg shadow">
                    <button
                        type="button"
                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5"
                        onClick={reset}
                    >
                        <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="#c6c7c7"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                    <div className="py-6 px-6 lg:px-8">
                        <h3 className="mb-4 text-xl font-medium text-gray-900">
                            Create Custom Report
                        </h3>

                        <form onSubmit={formik.handleSubmit} className="space-y-6">
                            <div>
                                <label
                                    htmlFor="clinics"
                                    className="block mb-2 text-sm font-medium text-gray-900"
                                >
                                    Select Clinic
                                </label>
                                <select
                                    name="clinicId"
                                    id="clinics"
                                    className="border text-sm rounded-lg block w-full p-2.5"
                                    onChange={handleClinicChange}
                                >
                                    <option value="">Select</option>
                                    <option value="all">All Clinics</option>
                                    {state.filteredClinics.map((clinic) => (
                                        <option
                                            key={clinic.id}
                                            value={clinic.id}
                                            data-location={clinic.location}
                                        >
                                            {clinic.location}
                                        </option>
                                    ))}
                                </select>
                                {formik.errors.clinicId ? (
                                    <p className="text-red-700 mt-2 text-xs">
                                        {formik.errors.clinicId}
                                    </p>
                                ) : null}
                            </div>

                            <div>
                                <label
                                    htmlFor="Present"
                                    className="block mb-2 text-sm font-medium text-gray-900"
                                >
                                    Select Report Range
                                </label>
                                <select
                                    name="Present"
                                    id="Present"
                                    className="border text-sm rounded-lg block w-full p-2.5"
                                    onChange={formik.handleChange}
                                    value={formik.values.Present}
                                >
                                    <option value="">Select</option>
                                    <option value="month">This Month</option>
                                    <option value="year">This Year</option>
                                    <option value="custom">Custom Range</option>
                                </select>
                                {formik.errors.Present ? (
                                    <p className="text-red-700 mt-2 text-xs">
                                        {formik.errors.Present}
                                    </p>
                                ) : null}
                            </div>

                            {state.customRange && (
                                <>
                                   <div className="grid grid-cols-2 gap-6">
                                   <div className="col-span-2 sm:col-span-1">
                                    <div>
                                        <label
                                            htmlFor="startDate"
                                            className="block mb-2 text-sm font-medium text-gray-900"
                                        >
                                            Start Date
                                        </label>
                                        <input
                                            type="date"
                                            name="startDate"
                                            id="startDate"
                                            className="border text-sm rounded-lg block w-full p-2.5"
                                            onChange={formik.handleChange}
                                            value={formik.values.startDate}
                                        />
                                    </div>
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">
                                    <div>
                                        <label
                                            htmlFor="endDate"
                                            className="block mb-2 text-sm font-medium text-gray-900"
                                        >
                                            End Date
                                        </label>
                                        <input
                                            type="date"
                                            name="endDate"
                                            id="endDate"
                                            className="border text-sm rounded-lg block w-full p-2.5"
                                            onChange={formik.handleChange}
                                            value={formik.values.endDate}
                                        />
                                    </div>
                                    </div>
                                    </div>
                                </>
                            )}

                            <button
                                type="submit"
                                className="w-full text-white bg-red-500 hover:bg-red-600 rounded-lg text-sm px-5 py-2.5"
                            >
                                Generate Report
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomReport;
