import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAnotherClinicIncomesData,
  fetchClinicIncomesData,
} from "../features/clinicIncome/clinicIncomeThunk";
import {
  selectClinicIncomes,
  selectClinicIncomesLoading,
  selectClinicIncomesError,
  selectAnotherData,
  selectAnotherLoading,
  selectAnotherError,
} from "../features/clinicIncome/clinicIncomeSlice";
import { formatDateToDDMMYY } from "../../specialMethod/getBetweenDate";

const useClinicIncomes = () => {
  const dispatch = useDispatch();
  const clinicIncomes = useSelector(selectClinicIncomes);
  const loading = useSelector(selectClinicIncomesLoading);
  const error = useSelector(selectClinicIncomesError);

  const anotherIncome = useSelector(selectAnotherData);
  const anotherloading = useSelector(selectAnotherLoading);
  const anothererror = useSelector(selectAnotherError);
  const currentDate = new Date();
  const date = formatDateToDDMMYY(currentDate);
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const pastDate = new Date(year, month - 1, 1);
  const formattedPastDate = formatDateToDDMMYY(pastDate);
  useEffect(() => {
    // Fetch clinic incomes data only if it hasn't been fetched yet
    console.log(clinicIncomes)
    if(clinicIncomes.totalAmount === 0){
      dispatch(fetchClinicIncomesData(date, date));
    }
    if(anotherIncome.totalAmount === 0){
      dispatch(fetchAnotherClinicIncomesData(formattedPastDate, date));
    }
  }, [dispatch, clinicIncomes]); // Include 'date' as a dependency
  return {
    clinicIncomes,
    loading,
    error,
    anotherIncome,
    anotherloading,
    anothererror,
  };
};

export default useClinicIncomes;