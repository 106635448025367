import React, { useState } from "react";
import medical from "../../../../assets/MedicalSymbol.png";
import docaid from "../../../../assets/doc-aid.png";
import {
  DIAGNOSIS_OPTIONS,
  COMPLAINTS_OPTIONS,
  TEST_OPTIONS,
  ADVICE_OPTIONS,
} from "../jsondara/data";
import CalendarInput from "../../appointment/calendarInput";
import {
  convertTo24Hour,
  formatDateToDDMMYY,
} from "../../../specialMethod/getBetweenDate";
import apiServices from "../../../../Service/apiService";
import EpresciptionDownload from "./EpresciptionDownload";
import UseTagInput from "./useTagInput";
import TagInput from "./tagInput";
import EyePrescriptionSection from "./eyePrescriptionSection";

const EPrescription = ({
  doctorDetails,
  patientData,
  reset,
  prescriptionPhotoURLs,
}) => {
  const [highbp, setHighbp] = useState("");
  const [lowbp, setLowbp] = useState("");
  const [pulse, setPulse] = useState("");
  const [temp, setTemp] = useState("");
  const [spo, setSpo] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [bmi, setBmi] = useState("");
  const [medicines, setMedicines] = useState([
    {
      name: "",
      composition: "",
      dose: "",
      when: "",
      frequency: "",
      duration: "",
      suggestions: [],
    },
  ]);
  const [presciptionDownload, setPresciptionDownload] = useState(false);
  const [prescriptionResult, setPrescriptionResult] = useState([]);

  const diagnosisInput = UseTagInput(DIAGNOSIS_OPTIONS);
  const complaintsInput = UseTagInput(COMPLAINTS_OPTIONS);
  const testsInput = UseTagInput(TEST_OPTIONS);
  const adviceInput = UseTagInput(ADVICE_OPTIONS);

  const renderSuggestions = (suggestions, index) => (
    <ul className="absolute bg-white border border-gray-300 max-h-60 overflow-auto z-10 w-full">
      {suggestions.map((suggestion, i) => (
        <li
          key={i}
          onClick={() => handleSuggestionClick(index, suggestion)}
          className="px-2 py-1 hover:bg-gray-200 cursor-pointer"
        >
          {suggestion.medicines}
        </li>
      ))}
    </ul>
  );

  const renderSelectOptions = (options) =>
    options.map((option, i) => (
      <option key={i} value={option}>
        {option}
      </option>
    ));

  const doseOptions = [
    "0-0-0",
    "1-0-1",
    "1-1-0",
    "0-1-1",
    "0-0-1",
    "0-1-0",
    "1-0-0",
  ];
  const whenOptions = [
    "After Food",
    "Before meal",
    "After meal",
    "Empty stomach",
    "Bed time",
  ];
  const frequencyOptions = [
    "Daily",
    "After 2 days",
    "Weekly",
    "Fortnightly",
    "Monthly",
    "Stat",
    "SOS",
  ];

  const MEDICINES_OPTIONS = async (searchTerm) => {
    try {
      const response = await apiServices.get(
        "edicine",
        "http://3.229.41.73:8080/medicineList/filterMedicineList",
        null,
        {
          medicine: searchTerm,
        },
        null
      );
      if (response) {
        const formattedMedicines = response.data.flatMap((item) => {
          // Iterate over medicinesWeights and create a separate object for each weight
          return item.medicinesWeights.map((medWeight) => ({
            medicines: `${
              item.medicineBrandName
            } - ${medWeight.weight.trim()}mg - ${item.medicineType}`,
            composition: item.composition,
          }));
        });
        return formattedMedicines;
      }
    } catch (error) {
      console.error("Error fetching medicines:", error);
      return [];
    }
  };

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const fetchMedicineSuggestions = debounce(async (searchTerm, index) => {
    if (searchTerm.length > 1) {
      const suggestions = await MEDICINES_OPTIONS(searchTerm);
      const updatedMedicines = [...medicines];
      updatedMedicines[index].suggestions = suggestions.filter((med) =>
        med.medicines.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setMedicines(updatedMedicines);
    }
  }, 500);

  // Handle suggestion click - Prevent API call after selection
  const handleSuggestionClick = (index, selectedMedicine) => {
    if (selectedMedicine) {
      // Update the medicine directly
      handleChange(index, "name", selectedMedicine.medicines);
      handleChange(index, "composition", selectedMedicine.composition);

      // Clear suggestions after selection
      const updatedMedicines = [...medicines];
      updatedMedicines[index].suggestions = [];
      setMedicines(updatedMedicines);
    } else {
      console.log("Medicine not found");
    }
  };

  // Handle input change - API call will be triggered only when the user types
  const handleChange = (index, field, value) => {
    const updatedMedicines = [...medicines];
    updatedMedicines[index][field] = value;

    // Only trigger the fetch function when the "name" field is changed
    if (field === "name" && !value.includes("-")) {
      // You can add a condition to check if it's not a selected suggestion
      updatedMedicines[index].suggestions = []; // Clear suggestions when user types
      setMedicines(updatedMedicines);
      fetchMedicineSuggestions(value, index); // Trigger API call for suggestions
    } else {
      setMedicines(updatedMedicines); // Update the state without making an API call
    }
  };

  const handleSubmit = () => {
    const presc = {
      highbp,
      lowbp,
      pulse,
      temp,
      spo,
      height,
      weight,
      bmi,
      diagnosis: diagnosisInput.selectedItems,
      complaint: complaintsInput.selectedItems,
      advice: adviceInput.selectedItems,
      test: testsInput.selectedItems,
      medicines,
    };
    setPrescriptionResult(presc);
    if (prescriptionResult) {
      setPresciptionDownload(true);
    }
    if (formatDateToDDMMYY(selectedDate) !== formatDateToDDMMYY(new Date())) {
      getNewPatient();
    }
  };
  const getNewPatient = async () => {
    const token = sessionStorage.getItem("token");
    const clinicData = doctorDetails.clinicDtos.find(
      (clinic) => clinic.location === patientData.clinicLocation
    );
    // toggleState("loadingSpinner", true);
    try {
      const response = await apiServices.post(
        "authentication",
        "/appointment/book1",
        {
          Authorization: " Bearer " + token,
        },
        {
          clinicId: clinicData.id,
        },
        {
          name: patientData.name,
          abhaNumber: patientData.abhaNumber,
          age: patientData.age,
          contact: patientData.contact,
          gender: patientData.gender,
          appointmentDate: formatDateToDDMMYY(selectedDate),
          appointmentTime: convertTo24Hour(patientData.appointmentTime),
          clinicLocation: patientData.clinicLocation,
          guardianName: patientData.guardianName,
          address: patientData.address,
        }
      );
      if (response.statusCode === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = () => {
    reset();
  };

  const addMedicine = () => {
    setMedicines([
      ...medicines,
      {
        name: "",
        composition: "",
        dose: "",
        when: "",
        frequency: "",
        duration: "",
        suggestions: [],
      },
    ]);
  };

  const deleteMedicine = (index) => {
    setMedicines(medicines.filter((_, i) => i !== index));
  };

  const getAllDegree = doctorDetails?.degree;
  console.log(getAllDegree);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [eyeData, setEyeData] = useState({});

  const handleDataChange = (data) => {
    setEyeData((prevData) => ({ ...prevData, ...data }));
    console.log("Eye Prescription Data:", { ...eyeData, ...data });
  };

  return (
    <>
      {" "}
      <div
        id="login-popup"
        tabIndex="-1"
        className="bg-black/50 fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
      >
        <div className="relative p-4 w-full max-w-6xl h-full md:h-[99%]">
          <div className="relative bg-white rounded-lg shadow w-full h-[99%] my-2 max-h-screen overflow-y-auto">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
              onClick={reset}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="#c6c7c7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close popup</span>
            </button>

            <div className="">
              <footer class="text-gray-600 body-font">
                <div class="container px-5 pt-6 pb-16 mx-auto">
                  <div class="flex flex-wrap -mb-10 -mx-4">
                    <div class="sm:w-1/3 px-4 text-left">
                      <div class="flex items-start w-full mb-0">
                        <img
                          src={medical}
                          alt="Medical"
                          class="w-16 h-16 object-cover rounded-full md:mr-4"
                        />
                        <div class="ml-4">
                          <span class="text-xl text-gray-900 block">
                            {doctorDetails.firstName} {doctorDetails.lastName}
                          </span>
                          <p class="text-sm text-gray-500">{getAllDegree}</p>
                          <p class="text-sm text-gray-500">
                            {doctorDetails.specialization}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="sm:w-1/4 px-4 pl-18 text-center">
                      <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
                        Location
                      </h2>
                      <nav class="list-none">
                        <li>
                          <span class="text-gray-600 hover:text-gray-800">
                            {patientData.clinicLocation}
                          </span>
                        </li>
                      </nav>
                    </div>
                    <div class="sm:w-1/4 px-4 pl-4 text-left">
                      <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
                        Contact
                      </h2>
                      <nav class="list-none">
                        <li>
                          <span class="text-gray-600 hover:text-gray-800">
                            {patientData.contact}
                          </span>
                        </li>
                        <li>
                          <span class="text-gray-600 hover:text-gray-800">
                            {doctorDetails.email}
                          </span>
                        </li>
                      </nav>
                    </div>
                    {/* <div class="sm:w-1/6 px-4">
                      <div class="w-full md:w-auto px-4 mb-6 md:ml-auto text-right">
                        <h2 class="title-font font-medium text-gray-900 tracking-widest text-[12px]">
                          Powered By:
                        </h2>
                        <img
                          src={docaid}
                          alt="Docaid Logo"
                          class="w-24 h-auto inline-block mt-2"
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </footer>
              <div className="flex items-center justify-around  pl-4 md:p-1 border-t-2 border-gray-200 rounded-b dark:border-black">
                {" "}
              </div>
              <div class="flex flex-wrap ">
                <div
                  className={`${
                    doctorDetails?.specialization == "Eye Specialist"
                      ? "w-2/3"
                      : ""
                  } `}
                >
                  <div className="border-2 border-gray-300 mx-5 px-5 py-1 rounded-xl mb-2">
                    <p className="text-xl text-black pb-1"> Vitals</p>
                    <section class="text-gray-600 body-font">
                      <div class="flex flex-wrap -m-2 -mx-2">
                        <div class={`p-4 ${
                    doctorDetails?.specialization == "Eye Specialist"
                      ? "w-1/3"
                      : "w-1/4"
                  }`}>
                          <span className="mr-6">
                            {" "}
                            BP{"  "}{" "}
                            <span className="border-2 border-gray-300 rounded w-10">
                              <input
                                className=" text-center w-10 border-1 border-gray-300"
                                placeholder="000"
                                value={highbp}
                                onChange={(e) => setHighbp(e.target.value)}
                              />
                            </span>{" "}
                            /
                            <span className="border-2 border-gray-300 rounded w-8">
                              <input
                                className=" text-center w-8 border-1 border-gray-300"
                                placeholder="00"
                                value={lowbp}
                                onChange={(e) => setLowbp(e.target.value)}
                              />
                            </span>{" "}
                            mmHg{" "}
                          </span>{" "}
                        </div>
                        <div class={`p-4 ${
                    doctorDetails?.specialization == "Eye Specialist"
                      ? "w-1/4"
                      : "w-1/4"
                  }`}>
                          <span className="mr-6">
                            {" "}
                            PULSE{" "}
                            <span className="border-2 border-gray-300 rounded w-10">
                              <input
                                className=" text-center w-10 border-1 border-gray-300"
                                placeholder="000"
                                value={pulse}
                                onChange={(e) => setPulse(e.target.value)}
                              />
                            </span>{" "}
                            bpm{" "}
                          </span>
                        </div>
                        <div class={`p-4 ${
                    doctorDetails?.specialization == "Eye Specialist"
                      ? "w-1/6"
                      : "w-1/4"
                  }`}>
                          <span className="mr-6">
                            {" "}
                            TEMP{" "}
                            <span className="border-2 border-gray-300 rounded w-10">
                              <input
                                className=" text-center w-10 border-1 border-gray-300"
                                placeholder="000"
                                value={temp}
                                onChange={(e) => setTemp(e.target.value)}
                              />
                            </span>{" "}
                            F{" "}
                          </span>
                        </div>
                        <div class={`p-4 ${
                    doctorDetails?.specialization == "Eye Specialist"
                      ? "w-1/6"
                      : "w-1/4"
                  }`}>
                          <span className="mr-6">
                            {" "}
                            SPO2
                            <span className="border-2 ml-2 border-gray-300 rounded w-10">
                              <input
                                className=" text-center w-10 border-1 border-gray-300"
                                placeholder="000"
                                value={spo}
                                onChange={(e) => setSpo(e.target.value)}
                              />
                            </span>{" "}
                            %{" "}
                          </span>
                        </div>
                        <div class={`p-4 ${
                    doctorDetails?.specialization == "Eye Specialist"
                      ? "w-1/3"
                      : "w-1/4"
                  }`}>
                          <span className="mr-6">
                            {" "}
                            HEIGHT
                            <span className="border-2 ml-2 border-gray-300 rounded w-10">
                              <input
                                className=" text-center w-10 border-1 border-gray-300"
                                placeholder="000"
                                value={height}
                                onChange={(e) => setHeight(e.target.value)}
                              />
                            </span>{" "}
                            cm{" "}
                          </span>
                        </div>
                        <div class={`p-4 ${
                    doctorDetails?.specialization == "Eye Specialist"
                      ? "w-1/4"
                      : "w-1/4"
                  }`}>
                          <span className="mr-6">
                            {" "}
                            WEIGHT
                            <span className="border-2 ml-2 border-gray-300 rounded w-10">
                              <input
                                className=" text-center w-10 border-1 border-gray-300"
                                placeholder="000"
                                value={weight}
                                onChange={(e) => setWeight(e.target.value)}
                              />
                            </span>{" "}
                            kg{" "}
                          </span>
                        </div>
                        <div class={`p-4  ${
                    doctorDetails?.specialization == "Eye Specialist"
                      ? "w-1/3"
                      : "w-1/4"
                  }`}>
                          <span className="mr-6">
                            {" "}
                            BMI
                            <span className="border-2 ml-2 border-gray-300 rounded w-10">
                              <input
                                className=" text-center w-10 border-1 border-gray-300"
                                placeholder="000"
                                value={bmi}
                                onChange={(e) => setBmi(e.target.value)}
                              />
                            </span>{" "}
                          </span>
                        </div>
                      </div>
                    </section>
                  </div>
                  <TagInput
                    label="Complaints"
                    placeholder="Type here"
                    options={COMPLAINTS_OPTIONS}
                    inputHook={complaintsInput}
                  />
                  <TagInput
                    label="Diagnosis"
                    placeholder="Type here"
                    options={DIAGNOSIS_OPTIONS}
                    inputHook={diagnosisInput}
                  />
                  <div className="border-2 border-gray-300 mx-5 px-5 py-3 rounded-xl mb-5">
                    <p className="text-xl text-black pb-1"> Medicine</p>
                    <p className="text-gray-500 mt-2">
                      <div className=" py-1 rounded-md mb-1">
                        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                          <table className="w-full text-sm text-left rtl:text-right text-black">
                            <thead className="text-xs text-black uppercase bg-gray-900 border-b border-gray-900">
                              <tr>
                                {[
                                  "#",
                                  "Name",
                                  "Dose",
                                  "When",
                                  "Frequency",
                                  "Duration",
                                  "Actions",
                                ].map((header, index) => (
                                  <th
                                    key={index}
                                    className="px-2 py-3 bg-gray-200 border-2 border-gray-900"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {medicines.map((medicine, index) => (
                                <tr
                                  key={index}
                                  className="border-2 border-gray-900"
                                >
                                  <th className="px-2 py-4 font-medium text-black whitespace-nowrap border-2 border-gray-900">
                                    {index + 1}
                                  </th>
                                  <td className="px-2 py-2 border-2 border-gray-900">
                                    <input
                                      type="text"
                                      className="bg-gray-100 border-b border-gray-400 w-full"
                                      value={medicine.name}
                                      onChange={(e) =>
                                        handleChange(
                                          index,
                                          "name",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <div className="text-[12px]">
                                      {medicine.composition}
                                    </div>
                                    {medicine.suggestions.length > 0 &&
                                      renderSuggestions(
                                        medicine.suggestions,
                                        index
                                      )}
                                  </td>
                                  <td className="px-2 py-4 border-2 border-gray-900">
                                    <select
                                      value={medicine.dose}
                                      onChange={(e) =>
                                        handleChange(
                                          index,
                                          "dose",
                                          e.target.value
                                        )
                                      }
                                    >
                                      {renderSelectOptions(doseOptions)}
                                    </select>
                                  </td>
                                  <td className="px-2 py-4 border-2 border-gray-900">
                                    <select
                                      value={medicine.when}
                                      onChange={(e) =>
                                        handleChange(
                                          index,
                                          "when",
                                          e.target.value
                                        )
                                      }
                                    >
                                      {renderSelectOptions(whenOptions)}
                                    </select>
                                  </td>
                                  <td className="px-2 py-4 border-2 border-gray-900">
                                    <select
                                      value={medicine.frequency}
                                      onChange={(e) =>
                                        handleChange(
                                          index,
                                          "frequency",
                                          e.target.value
                                        )
                                      }
                                    >
                                      {renderSelectOptions(frequencyOptions)}
                                    </select>
                                  </td>
                                  <td className="px-2 py-4 border-2 border-gray-900">
                                    <input
                                      type="text"
                                      className="px-1 w-16 bg-gray-100 border-b border-gray-600"
                                      placeholder="1 month"
                                      value={medicine.duration}
                                      onChange={(e) =>
                                        handleChange(
                                          index,
                                          "duration",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="px-2 py-4 border-2 border-gray-900">
                                    <svg
                                      className="w-5 h-5 text-gray-900 fill-current cursor-pointer"
                                      onClick={() => deleteMedicine(index)}
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M9 2a1 1 0 011 1v1h6a1 1 0 110 2h-1v11a2 2 0 01-2 2H7a2 2 0 01-2-2V6H4a1 1 0 110-2h6V3a1 1 0 011-1zM7 6v11h6V6H7zm2-3v1h2V3H9z" />
                                    </svg>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div
                            className="text-right text-teal-700 py-3 cursor-pointer mr-2"
                            onClick={addMedicine}
                          >
                            Add more medicine
                          </div>
                        </div>
                      </div>
                    </p>
                  </div>
                  <TagInput
                    label="Tests"
                    placeholder="Type here"
                    options={TEST_OPTIONS}
                    inputHook={testsInput}
                  />
                  <TagInput
                    label="Advice"
                    placeholder="Type here"
                    options={ADVICE_OPTIONS}
                    inputHook={adviceInput}
                  />
                  <div className="border-2 border-gray-300 mx-5 px-5 py-3 rounded-xl mb-5">
                    <p className="text-xl text-black pb-1"> Next Visit</p>
                    <p className="text-gray-500 mt-2">
                      <div className=" py-1 rounded-md mb-1">
                        {/*  */}
                        <div className="w-ful mx-auto">
                          {/* Selected Complaint (Tags) */}
                          <div className="flex flex-wrap gap-2 mb-2">
                            <div className="col-span-2 sm:col-span-1">
                              <CalendarInput
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                              />
                            </div>
                          </div>
                        </div>
                        {/*  */}
                      </div>
                    </p>
                  </div>
                  <div className="flex items-center p-1 md:p-5 mb-2 border-gray-200 rounded-b dark:border-gray-600">
                    <div className="flex">
                      {/* <button
                    data-modal-hide="default-modal"
                    type="button"
                    onClick={handleSave}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Save & Draft
                  </button> */}
                      <button
                        data-modal-hide="default-modal"
                        type="submit"
                        onClick={handleSubmit}
                        className="ms-3 text-gray-100 bg-green-500 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                {doctorDetails?.specialization == "Eye Specialist" && (
                  <EyePrescriptionSection onDataChange={handleDataChange} />
                )}
              </div>
            </div>
          </div>
        </div>
        {presciptionDownload && (
          <EpresciptionDownload
            prescriptionPhotoURLs={prescriptionPhotoURLs}
            doctorDetails={doctorDetails}
            patientData={patientData}
            reset={() => setPresciptionDownload(false)}
            prescriptionResult={prescriptionResult}
            eyeData={eyeData}
            selectedDate={selectedDate}
          />
        )}
      </div>
    </>
  );
};

export default EPrescription;
