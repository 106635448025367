// components/CustomToolbar.js
import React, { useEffect, useState } from "react";
import "../../../App.css";
import { FaDotCircle } from "react-icons/fa";
const CustomToolbar = (toolbar) => {
  const [state, setState] = useState({
    activeViewWeek: "",
    activeViewDay: "",
    activeViewMonth: "",
  });

  const toggleState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const [activeViewDay, setActiveViewDay] = useState("");
  const [activeViewMonth, setActiveViewMonth] = useState("");
  const goToBack = () => {
    toolbar.onNavigate("PREV");
  };

  const goToNext = () => {
    toolbar.onNavigate("NEXT");
  };

  const goToToday = () => {
    toolbar.onNavigate("TODAY");
  };
  const weekView = (view) => {
    toggleState("activeViewWeek", view);
    toolbar.onView("week");
    toggleState("activeViewDay", "");
    toggleState("activeViewMonth", "");
  };
  const dayView = (view) => {
    toggleState("activeViewDay", view);
    toolbar.onView("day");
    toggleState("activeViewWeek", "");
    toggleState("activeViewMonth", "");
  };
  const monthView = (view) => {
    toggleState("activeViewMonth", view);
    toolbar.onView("agenda");
    toggleState("activeViewDay", "");
    toggleState("activeViewWeek", "");
  };
  useEffect(() => {
    weekView();
    toggleState("activeViewWeek", "");
  }, []);

  return (
    <>
      <div className="flex ">
        <div
          className="font-bold text-gray-800 mt-4 md:text-xl"
          style={{ width: "300px", height: "50px", padding: "10px" }}
        >
          {toolbar.label}
        </div>

        <div className="md:ml-[350px] ml-[-60px]">
          <div>
            <button
              type="button"
              className={`md:font-semibold ${
                state.activeViewWeek === "week" ? "underline text-blue-900" : ""
              }`}
              onClick={() => weekView("week")}
            >
              Week
            </button>
            <button
              type="button"
              className={`px-1 md:px-2 md:font-semibold ${
                state.activeViewDay === "day" ? "underline text-blue-900" : ""
              }`}
              onClick={() => dayView("day")}
            >
              Day
            </button>
            <button
              type="button"
              className={`md:font-semibold ${
                state.activeViewMonth === "agenda"
                  ? "underline text-blue-900"
                  : ""
              }`}
              onClick={() => monthView("agenda")}
            >
              Agendas
            </button>
          </div>
          <div className="">
            <button
              type="button"
              className="pr-2 pl-14"
              onClick={goToBack}
              style={{ fontSize: "32px" }}
            >
              {"<"}
            </button>
            <button type="button" className="pr-2" onClick={goToToday}>
              <FaDotCircle />
            </button>
            <button
              type="button"
              onClick={goToNext}
              style={{ fontSize: "32px" }}
            >
              {">"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomToolbar;
