import React, { useState, useEffect } from "react";
import useData from "../../redux/customHook/useData";

const Invoice = ({ reset, invoiceDetails }) => {
  const { data } = useData();
  const [amount, setAmount] = useState(0);
  const [gst, setGst] = useState(0);

  useEffect(() => {
    if (invoiceDetails && invoiceDetails.amount) {
      const calculatedAmount = (invoiceDetails.amount * 100) / 118;
      setAmount(calculatedAmount.toFixed(2)); // Ensure two decimal places
      setGst((invoiceDetails.amount - calculatedAmount).toFixed(2));
    }
  }, [invoiceDetails]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div
      id="login-popup"
      tabIndex="-1"
      className="bg-black/50 fixed inset-0 z-50 flex items-center justify-center"
    >
      <div className="relative p-4 w-full max-w-4xl h-full md:h-[99%]">
        <div className="relative bg-white rounded-lg shadow w-full h-[99%] my-2 max-h-screen overflow-y-auto">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            onClick={reset}
            aria-label="Close popup"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="#c6c7c7"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          <div className="p-5">
            <footer className="text-gray-600 body-font">
              <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
                <div className="lg:w-3/4 mb-24 md:mb-0">
                  <img
                    alt="Company Logo"
                    className="w-[120px] xl:w-[170px] lg:w-auto mb-24 md:mb-0"
                    src="https://d2sv8898xch8nu.cloudfront.net/MediaFiles/doc-aid.png"
                  />
                </div>
                <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
                  <div className="lg:w-3/4 mb-24 md:mb-0">
                    <img
                      alt="Company Stamp"
                      className="w-[80px] xl:w-[80px] lg:w-auto mb-24 md:mb-0"
                      src="https://d2sv8898xch8nu.cloudfront.net/MediaFiles/reshita.jpg"
                    />
                  </div>
                </span>
              </div>
            </footer>

            <section className="text-gray-600 body-font">
              <div className="container mx-auto">
                <div className="flex flex-col sm:flex-row items-start mx-auto">
                  <div className="flex-grow sm:pr-16 text-md font-medium title-font text-gray-900">
                    <div className="px-5 py-2">
                      <div className="text-gray-900 font-bold">From</div>
                      <div className="text-lg font-bold">M/s Reshita</div>
                      <div className="text-lg">
                        Flat no. 33 Mangaldeep Apartment
                      </div>
                      <div>Patliputra Colony, Patna, 800013</div>
                    </div>
                  </div>
                  <div className="flex-shrink-0 border-0 py-2 px-8 rounded text-lg mt-10 sm:mt-0">
                    <div className="text-gray-900 font-bold">Bill to</div>
                    <div>{`${data.firstName} ${data.lastName}`}</div>
                    <div>{data.email}</div>
                    <div>{data.contact}</div>
                  </div>
                </div>
              </div>
            </section>

            <div className="px-5">
              <div className="pt-6">
                <span className="font-bold">Payment Id: </span>
                {invoiceDetails.paymentId}
              </div>
              <div className="py-1">
                <span className="font-bold">Order Id: </span>
                {invoiceDetails.orderId}
              </div>
              <div>
                <span className="font-bold">Payment Date: </span>
                {invoiceDetails.paymentDate}
              </div>

              <div className="text-xl font-bold text-red-400 mt-4">
                Your Account Summary
              </div>
              <div className="text-md text-black font-semibold mt-2">
                Subscription Charges
              </div>

              <div className="bg-gray-100 my-5">
                <div className="container mx-auto py-4 px-10 flex flex-wrap flex-col sm:flex-row">
                  <p className="text-black font-semibold text-sm text-center sm:text-left">
                    Previous Balance
                  </p>
                  <span className="text-black font-semibold inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                    0.00
                  </span>
                </div>
                <div className="container mx-auto px-10 flex flex-wrap flex-col sm:flex-row">
                  <p className="text-black font-semibold text-sm text-center sm:text-left">
                    Amount
                  </p>
                  <span className="text-black font-semibold inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                    {amount}
                  </span>
                </div>
                <div className="container mx-auto py-4 px-10 flex flex-wrap flex-col sm:flex-row">
                  <p className="text-black font-semibold text-sm text-center sm:text-left">
                    18% GST
                  </p>
                  <span className="text-black font-semibold inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                    {gst}
                  </span>
                </div>
                <div className="container mx-auto py-4 px-10 flex flex-wrap flex-col sm:flex-row">
                  <p className="text-black font-semibold text-sm text-center sm:text-left">
                    Total Amount
                  </p>
                  <span className="text-black font-semibold inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                    {invoiceDetails.amount.toFixed(2)}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex items-center p-1 md:p-5 mb-2 border-gray-200 rounded-b">
              <button
                type="button"
                onClick={handlePrint}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
