import React, { useEffect, useState } from "react";
import apiServices from "../../../Service/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "../loading/spinner";
import ModalSelect from "./modalSelect";
import { updateScheduleSuccess } from "../../redux/features/data/dataSlice";
import { useDispatch } from "react-redux";

const EditSchedule = ({ reset, event }) => {
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const [state, setState] = useState({
    deleted: false,
    loadingSpinner: false,
  });

  const toggleState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const hanldeDoctorSchedule = async (values) => {
    toggleState("loadingSpinner", true);

    try {
      const response = await apiServices.put(
        "authentication",
        "/inter/update",
        {
          Authorization: " Bearer " + token,
        },
        {
          doctorInterfaceId: event.id,
        },
        {
          purpose: values.purpose,
          clinicName: values.clinicName,
          startTime: values.startTime,
          endTime: values.endTime,
          stDate: values.stDate,
          endDate: values.endDate,
        }
      );
      if (response.statusCode === 200) {
        toggleState("loadingSpinner", false);

        dispatch(updateScheduleSuccess(response.data));
        reset();
      }
    } catch (error) {
      console.error("error is coming ", error);
      if (error.response.data.statusCode === 404) {
        toast.error("Something Went Wrong", {
          position: "top-center",
        });
      }
    } finally {
      toggleState("loadingSpinner", false);
    }
  };
  const formik = useFormik({
    initialValues: {
      purpose: event ? event.purpose : "",
      clinicName: event ? event.clinicName : "",
      startTime: event ? event.startTime : "",
      endTime: event ? event.endTime : "",
      stDate: event ? event.stDate : "",
      endDate: event ? event.endDate : "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      purpose: Yup.string().required("Purpose is required"),
      clinicName: Yup.string().required("clinicName is required"),
      startTime: Yup.string().required("Starttime is required"),
      endTime: Yup.string().required("endTime is required"),
      stDate: Yup.string().required("startDate is required"),
      endDate: Yup.string().required("endDate is required"),
    }),
    onSubmit: (values) => {
      hanldeDoctorSchedule(values);
    },
  });
  const handleDelete = () => {
    toggleState("deleted", true);
  };
  const handleResetDelete = () => {
    toggleState("deleted", false);
    reset();
  };

  return (
    <>
      <div
        id="login-popup"
        tabIndex="-1"
        className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
      >
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
              onClick={reset}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="#c6c7c7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  cliprule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close popup</span>
            </button>
            <div className="p-5">
              {/* <h3 className="text-2xl mb-0.5 font-medium"></h3> */}
              <p className="mb-4 text-sm font-normal text-gray-800"></p>

              <div className="text-center">
                <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
                  Edit Schedule
                </p>
              </div>

              {/* form */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="expiration-date"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Purpose
                    </label>
                    <input
                      type="text"
                      name="purpose"
                      value={formik.values.purpose}
                      onChange={formik.handleChange}
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                      id="service"
                      placeholder="Purpose"
                    />
                    {formik.touched.purpose && formik.errors.purpose ? (
                      <p style={{ color: "red" }}>{formik.errors.purpose}</p>
                    ) : null}
                  </div>

                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="cvv"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Location
                    </label>
                    <input
                      type="text"
                      id="cvv"
                      name="clinicName"
                      value={formik.values.clinicName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Location"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.clinicName && formik.errors.clinicName ? (
                      <p style={{ color: "red" }}>{formik.errors.clinicName}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1 mb-6">
                    <label
                      for="card-holder"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Start Time
                    </label>
                    <input
                      type="time"
                      name="startTime"
                      value={formik.values.startTime}
                      onChange={formik.handleChange}
                      id="card-holder"
                      placeholder="Start Time"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.startTime && formik.errors.startTime ? (
                      <p style={{ color: "red" }}>{formik.errors.startTime}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="card-holder"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      End Time
                    </label>
                    <input
                      type="time"
                      name="endTime"
                      value={formik.values.endTime}
                      onChange={formik.handleChange}
                      id="card-holder"
                      placeholder="Start Time"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.endTime && formik.errors.endTime ? (
                      <p style={{ color: "red" }}>{formik.errors.endTime}</p>
                    ) : null}
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="card-number"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      From
                    </label>
                    <input
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                      id="service"
                      type="date"
                      name="stDate"
                      value={formik.values.stDate}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.stDate && formik.errors.stDate ? (
                      <p style={{ color: "red" }}>{formik.errors.stDate}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1 ">
                    <label
                      for="expiration-date"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      To
                    </label>
                    <input
                      type="date"
                      name="endDate"
                      value={formik.values.endDate}
                      onChange={formik.handleChange}
                      id="expiration-date"
                      placeholder="MM / YY"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.endDate && formik.errors.endDate ? (
                      <p style={{ color: "red" }}>{formik.errors.endDate}</p>
                    ) : null}
                  </div>
                </div>
                {/* <div className="mt-8">
                <button
                  type="submit"
                  className="w-full bg-green-500  text-white font-medium py-3 rounded-lg focus:outline-none"
                >
                  Edit
                </button>
              </div> */}

                <div className="mt-8 mx-auto">
                  <div className="flex flex-wrap -m-2">
                    <div className="p-2 w-1/2">
                      <div className="relative">
                        <button
                          type="button"
                          onClick={handleDelete}
                          className="w-full bg-red-400  rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 text-base outline-none text-white py-1 px-16 leading-8 transition-colors duration-200 ease-in-out"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                    <div className="p-2 w-1/2">
                      <div className="relative">
                        <button
                          type="submit"
                          className="w-full bg-green-500  rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 text-base outline-none text-white py-1 px-16 leading-8 transition-colors duration-200 ease-in-out"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {state.deleted && <ModalSelect reset={handleResetDelete} event={event} />}
      <ToastContainer />
      {state.loadingSpinner && <Spinner />}
    </>
  );
};

export default EditSchedule;
