import { useFormik  } from "formik";
import React, { useState , useRef} from "react";
import * as Yup from "yup";
import apiServices from "../../../Service/apiService";
import { useDispatch } from "react-redux";
import { updateClinicSuccess } from "../../redux/features/data/dataSlice";
import Spinner from "../loading/spinner";
import ImageCropper from "./imageCropper";

const EditClinic = ({ clinic, reset }) => {
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [image, setImage] = useState("");
  const inputRef = useRef();
  const [currentPage, setCurrentPage] = useState(false);
  // update action based on action update
  const dispatch = useDispatch();
  // validate formik
  const formik = useFormik({
    initialValues: {
      clinicName: clinic ? clinic.clinicName : "",
      location: clinic ? clinic.location : "",
      startTime: clinic ? clinic.startTime : "",
      endTime: clinic ? clinic.endTime : "",
      incharge: clinic ? clinic.incharge : "",
      clinicContact: clinic ? clinic.clinicContact : "",
      clinicNewFees: clinic ? clinic.clinicNewFees : "",
      clinicOldFees: clinic ? clinic.clinicOldFees : "",
      days: clinic ? clinic.days : [],
      dropdownOpen: false,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      clinicName: Yup.string().required("enter clinic name "),
      location: Yup.string().required(" enter location"),
      startTime: Yup.string().required("enter start time"),
      endTime: Yup.string().required("enter end time"),
      incharge: Yup.string().required("enter incharge name"),
      clinicContact: Yup.string()
        .matches(/^\d{10}$/, "Mobile Number must be 10 digits")
        .required("enter Mobile  Number"),
      clinicNewFees: Yup.number().required("add clinic fees"),
      clinicOldFees: Yup.number().required("add clinic fees"),
      days: Yup.array().required("please add days"),
    }),
    onSubmit: (values) => {
      handleEditFunction(values);
    },
  });

  const handleToggleDay = (day) => {
    day = day.toUpperCase(); // Convert day to uppercase
    const selectedDays = formik.values.days;
    const index = selectedDays.indexOf(day);
    if (index > -1) {
      // Day is already selected, remove it
      const updatedDays = selectedDays.filter(
        (selectedDay) => selectedDay !== day
      );

      formik.setFieldValue("days", updatedDays);
    } else {
      const updatedDays = [...selectedDays, day];

      formik.setFieldValue("days", updatedDays);
    }
  };

  const handleEditFunction = async (values) => {
    try {
      setLoadingSpinner(true);
      const response = await apiServices.put(
        "authentication",
        `/clinic/update/${clinic.id}`,
        null,
        null,
        {
          clinicName: values.clinicName,
          location: values.location,
          startTime: values.startTime,
          endTime: values.endTime,
          incharge: values.incharge,
          clinicContact: values.clinicContact,
          clinicNewFees: values.clinicNewFees,
          clinicOldFees:values.clinicOldFees,
          days: values.days,
        }
      );
      if (response.statusCode === 200) {
        dispatch(updateClinicSuccess(response.data));
        if(image){
          handleImageUpload(clinic.id);
        }
        reset();
      }
    } catch (error) {
      console.error("error", error.response);
    }finally {
      setLoadingSpinner(false);
    }
  };

  const handleOnChange = (event) => {
    console.log("nes")
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function (e) {
        onImageSelected(reader.result);
      };
    }
  };

  const deletePrescriptionImg = async()=>{
    console.log(clinic.id)
    setLoadingSpinner(true);
    try {
      const response = await apiServices.delete(
        "authentication",
        `/clinicImage/delete`,
        null,
        {
          clinicId: clinic.id
        },
        null
      );
      if (response) {
        setLoadingSpinner(false);
      }
    } catch (error) {
      console.error("error in deleting ", error);
    } finally {
      setLoadingSpinner(false);
    }
  }


  const onChooseImg = () => {
    console.log("hel")
    inputRef.current.click();
  };

  const handleImageUpload = async (ClinicId) => {
    try {
      const base64String = image;
       console.log("image",image)
      // Extract image type from base64 string
      const typeMatch = base64String.match(/^data:(image\/\w+);base64,/);
      if (!typeMatch) {
        throw new Error("Invalid base64 string");
      }
      const imageType = typeMatch[1].split("/")[1];

      // Convert base64 to Blob
      const byteCharacters = atob(base64String.split(",")[1]);
      const byteArray = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteArray], { type: `image/${imageType}` });

      // Create a file from the Blob
      const filename = `image.${imageType}`;
      const file = new File([blob], filename, { type: `image/${imageType}` });
      console.log("file",file)
      // Prepare image data for upload
      const imageData = new FormData();
      imageData.append("file", file);
      console.log("imageDate",imageData)
      const token = sessionStorage.getItem("token");
      const response = await apiServices.put(
        "authentication",
        "/clinicImage/upload",
        {
          Authorization: " Bearer " + token,
        },
        {
          clinicId: ClinicId,
        },
        imageData
      );
      if (response) {
        reset();
        return response;
      }
    } catch (error) {
      console.error("Error is coming in api", error);
    }
  };
  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );
      const dataURL = canvasEle.toDataURL("image/jpeg");
      setCurrentPage(false);
      setImage(dataURL);
  }};
  const onCropCancel = () => {
    setCurrentPage(false);
    setImage("");
  };
  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setCurrentPage(true);
  };

  return (
    <>
      <div
        id="login-popup"
        tabIndex="-1"
        className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
      >
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
              onClick={reset}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="#c6c7c7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  cliprule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close popup</span>
            </button>
            <div className="p-5">
              <p className="mb-4 text-sm font-normal text-gray-800"></p>

              <div className="text-center">
                <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
                  Clinic Details
                </p>
              </div>

              {/* form */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                <div className="grid grid-cols-2 gap-6">
                <div className="col-span-2 sm:col-span-1">
                    <label
                      for="card-number"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Prescription Photo
                    </label>
                    <div>
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={handleOnChange}
        style={{ display: "none" }}
      />

<p className="text-blue-600 text-md flex items-center">
 <p  onClick={onChooseImg}> {!image ? "Choose Image" : "Image Already Chosen"}</p>
  <span className="pl-12" onClick={deletePrescriptionImg}>
    <svg
      className="w-6 h-6 text-red-500 fill-current"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 2a1 1 0 011 1v1h6a1 1 0 110 2h-1v11a2 2 0 01-2 2H7a2 2 0 01-2-2V6H4a1 1 0 110-2h6V3a1 1 0 011-1zM7 6v11h6V6H7zm2-3v1h2V3H9z" />
    </svg>
  </span>
</p>

    </div>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="card-number"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Clinic Name
                    </label>
                    <input
                      type="text"
                      name="clinicName"
                      value={formik.values.clinicName}
                      onChange={formik.handleChange}
                      id="card-number"
                      placeholder="Clinic Name"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.clinicName && formik.errors.clinicName ? (
                      <p style={{ color: "red" }}>{formik.errors.clinicName}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="expiration-date"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      name="location"
                      value={formik.values.location}
                      onChange={formik.handleChange}
                      id="expiration-date"
                      placeholder="Address"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.location && formik.errors.location ? (
                      <p style={{ color: "red" }}>{formik.errors.location}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="expiration-date"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Start Time
                    </label>
                    <input
                      type="time"
                      name="startTime"
                      value={formik.values.startTime}
                      onChange={formik.handleChange}
                      id="expiration-date"
                      placeholder="Start time"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.startTime && formik.errors.startTime ? (
                      <p style={{ color: "red" }}>{formik.errors.startTime}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="cvv"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      End Time
                    </label>
                    <input
                      type="time"
                      name="endTime"
                      value={formik.values.endTime}
                      onChange={formik.handleChange}
                      id="cvv"
                      placeholder="endTime"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.endTime && formik.errors.endTime ? (
                      <p style={{ color: "red" }}>{formik.errors.endTime}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="expiration-date"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Incharge
                    </label>
                    <input
                      type="text"
                      name="incharge"
                      value={formik.values.incharge}
                      onChange={formik.handleChange}
                      id="expiration-date"
                      placeholder="Incharge Name"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.incharge && formik.errors.incharge ? (
                      <p style={{ color: "red" }}>{formik.errors.incharge}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="cvv"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Mobile Number
                    </label>
                    <input
                      type="number"
                      name="clinicContact"
                      value={formik.values.clinicContact}
                      onChange={formik.handleChange}
                      id="cvv"
                      placeholder="Mobile Number"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.clinicContact &&
                    formik.errors.clinicContact ? (
                      <p style={{ color: "red" }}>
                        {formik.errors.clinicContact}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="cvv"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Clinic New Patient Fee
                    </label>
                    <input
                      type="number"
                      name="clinicNewFees"
                      value={formik.values.clinicNewFees}
                      onChange={formik.handleChange}
                      id="cvv"
                      placeholder="₹"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.clinicNewFees && formik.errors.clinicNewFees ? (
                      <p style={{ color: "red" }}>{formik.errors.clinicNewFees}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="cvv"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Clinic Old Patient Fee
                    </label>
                    <input
                      type="number"
                      name="clinicOldFees"
                      value={formik.values.clinicOldFees}
                      onChange={formik.handleChange}
                      id="cvv"
                      placeholder="₹"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.clinicOldFees && formik.errors.clinicOldFees ? (
                      <p style={{ color: "red" }}>{formik.errors.clinicOldFees}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="dropdownRadioButton"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Select Days
                    </label>
                    <div className="relative">
                      <button
                        id="dropdownRadioButton"
                        className="text-gray-700 bg-white border border-gray-400 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-6 py-3.5 text-center inline-flex items-center"
                        type="button"
                        onClick={() =>
                          formik.setFieldValue(
                            "dropdownOpen",
                            !formik.values.dropdownOpen
                          )
                        }
                      >
                        {formik.values.days.length} Days
                        <svg
                          className="w-2.5 h-2.5 ms-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      </button>
                      {formik.values.dropdownOpen && (
                        <div
                          id="dropdownDefaultRadio"
                          className="absolute z-10 w-48 bg-white divide-y divide-gray-100 rounded-lg shadow-lg mt-1"
                          style={{ backgroundColor: "rgb(240,239,250)" }}
                        >
                          <ul className="p-3 space-y-1 text-sm text-gray-900">
                            {[
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                              "Sunday",
                            ].map((day) => (
                              <li key={day}>
                                <div className="flex items-center p-2 rounded hover:bg-gray-100">
                                  <input
                                    id={`checkbox-item-${day}`}
                                    type="checkbox"
                                    name="days"
                                    value={day}
                                    checked={formik.values.days.includes(
                                      day.toUpperCase()
                                    )}
                                    onChange={() =>
                                      handleToggleDay(day.toUpperCase())
                                    }
                                    className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                  />
                                  <label
                                    htmlFor={`checkbox-item-${day}`}
                                    className="ml-2 text-sm font-medium"
                                  >
                                    {day}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-8">
                  <button
                    type="submit"
                    className="w-full  hover:bg-blue-600 text-white font-medium py-3 rounded-lg focus:outline-none dark:hover:text-black"
                    style={{ backgroundColor: "rgb(251,146,31)" }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {currentPage &&  
        <ImageCropper
          image={image}
          onCropDone={onCropDone}
          onCropCancel={onCropCancel}
        />}
      
      {loadingSpinner && <Spinner />}
    </>
  );
};

export default EditClinic;
