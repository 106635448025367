import React, { useState} from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../../component/../App.css";
import useData from "../../redux/customHook/useData";


const MonthCalendar = () => {
  const [newdate, setDate] = useState(new Date());

  const { data } = useData();
  let events =[]
  if(data){
     events =data.docIntr
  }
  return (
    <div>
      <div className=" flex justify-start items-start">
     
        <Calendar
          onChange={setDate}
          value={newdate}
          className="rounded-lg shadow-lg ml-0"
          tileClassName={({ date }) => {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            if (date.getMonth() < 10) {
              month = "0" + month;
            }
            if (date.getDate() < 10) {
              day = "0" + day;
            }
            const realDate = date.getFullYear() + "-" + month + "-" + day;
           
            if (events.find((val) => val.stDate === realDate)) {
              return "highlight";
            }
          }}
        /> 
      </div>
     
           
    </div>
  );
};

export default MonthCalendar;
