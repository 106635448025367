import React, { useState } from "react";
import AddClinic from "../clinics/addClinic";
import {
  loadScript,
  handleAmountCalculateClinic,
  handleOrderFunction,
  handleVerifyPayment,
  handleSavedEnd,
  handleGetEndDate,
} from "../../razorrpay/razorpay";
import { toggleState } from "../../specialMethod/toggleState";

const AlertClinicModal = ({ value, updatedClinics, data }) => {
  const [state, setState] = useState({
    addClinic: false,
  });

  const closeAddClinicModal = () => {
    toggleState("addClinic", false, setState);
  };
  const token = sessionStorage.getItem("token");
  const handleContinueButton = () => {
    if (Object.keys(data.clinicDtos).length !== 0) {
     handlepayment();
    } else {
      toggleState("addClinic", true, setState);
    }
  };
  const handlepayment = async () => {
    const date = await handleGetEndDate(token, data.id);
    if (data) {
      dayCalculate(date);
    }
  };
  const dayCalculate = async (sub_endDate) => {
    const sub_endDateFormat = new Date(sub_endDate);
    const today = new Date();
    sub_endDateFormat.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    const differenceInTime = sub_endDateFormat - today;
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    console.log(differenceInDays);
    const amount = await handleAmountCalculateClinic(
      differenceInDays,
      sub_endDateFormat,
      token
    );
    if (amount) {
      const order_id = await handleOrderFunction(amount);
      console.log("s", order_id);
      await handleRazorpayScreen(amount, order_id);
    }
  };
  const handleRazorpayScreen = async (amount, order_id) => {
    const res = await loadScript("https:/checkout.razorpay.com/v1/checkout.js");
    if (!res) {
      console.log("some error");
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: amount,
      currency: "INR",
      name: "Doc-aid",
      description: "hello",
      order_id: order_id,
      image: "https://d2sv8898xch8nu.cloudfront.net/MediaFiles/doc-aid.png",
      handler: function (response) {
        console.log(response);
        handleverify(response, order_id, amount);
      },
      prefill: {
        name: data.firstName + data.lastName,
        email: data.email,
        contact: data.contact,
      },
      theme: {
        color: "#F37254",
      },
    };
    const paymentsObject = new window.Razorpay(options);
    paymentsObject.open();
  };
  const handleverify = async (razorpayresponse, order_id, amount) => {
    const verify = await handleVerifyPayment(razorpayresponse);
    const token = sessionStorage.getItem("token");
    const date = await handleGetEndDate(token, data.id);
    if (verify) {
      const m = await handleSavedEnd(
        razorpayresponse,
        1,
        order_id,
        amount,
        date,
        data
      );
      if (m) {
        toggleState("addClinic", true, setState);
      }
    }
  };

  return (
    <>
      {!state.addClinic && (
        <div
          id="login-popup"
          tabIndex="-1"
          className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex "
        >
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-500 opacity-30"></div>
              </div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div
                    className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10"
                    style={{ backgroundColor: "rgb(255,229,205)" }}
                  >
                    <svg
                      className="h-6 w-6 text-green-10"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Additional Clinic Charges
                    </h3>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                      type="button"
                      onClick={handleContinueButton}
                      className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2  text-base leading-6 font-medium text-white shadow-sm  focus:outline-none focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 dark:hover:text-black"
                      style={{ backgroundColor: "rgb(7,160,165)" }}
                    >
                      Accept
                    </button>
                  </span>
                  <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                      type="button"
                      onClick={value}
                      className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                      Cancel
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {state.addClinic && (
        <AddClinic
          value={closeAddClinicModal}
          closeAlertClinicBanner={value}
          updatedClinics={updatedClinics}
        />
      )}
    </>
  );
};

export default AlertClinicModal;
