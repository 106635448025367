import React, { useEffect, useState } from "react";
import apiServices from "../../../Service/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "../loading/spinner";
import useData from "../../redux/customHook/useData";
import { useDispatch } from "react-redux";
import { addSchedule } from "../../redux/features/data/dataSlice";
import {
  convertTo24Hour,
  formatDateToDDMMYY,
  formatTime,
} from "../../specialMethod/getBetweenDate";

const AddScheduleForDoctor = ({ reset, updatedSchedule }) => {
  const token = sessionStorage.getItem("token");
  const dispatch = useDispatch();

  const toggleState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const [state, setState] = useState({
    loadingSpinner: false,
    timeSlots: [],
    checkSchedule: [],
    collectScheduleData: [],
    short : false
  });

  const { data } = useData();
  useEffect(() => {
    if (data) {
      toggleState("loadingSpinner", false);
      toggleState("collectScheduleData", data.docIntr || []);
    }
  }, [data]);

  const handleDoctorSchedule = async (values) => {
    toggleState("loadingSpinner", true);
    try {
      const response = await apiServices.post(
        "authentication",
        "/inter/add",
        {
          Authorization: " Bearer " + token,
        },
        null,
        {
          purpose: values.purpose,
          clinicName: values.clinicName,
          startTime: convertTo24Hour(values.startTime),
          endTime: convertTo24Hour(values.endTime),
          stDate: values.stDate,
          endDate: values.endDate,
        }
      );

      if (response.statusCode === 200) {
        const updatedScheduleData = response.data;
        dispatch(addSchedule(updatedScheduleData));
        updatedSchedule(updatedScheduleData);
        toggleState("loadingSpinner", false);
        reset();
      }
    } catch (error) {
      console.error("error is coming ", error);
      if (error.response.data.statusCode === 404) {
        toast.error("Something Went Wrong", {
          position: "top-center",
        });
      }
    } finally {
      toggleState("loadingSpinner", false);
    }
  };
  const handleshortDaySchedule = async (values) => {
    console.log("hello")
    toggleState("loadingSpinner", true);
          const stdDate = new Date(values.stDate); 
          const enDate = new Date(values.endDate);
          stdDate.setHours(0, 0, 0, 0);
          enDate.setHours(0, 0, 0, 0);
          const leftDays = (enDate - stdDate) / (1000 * 60 * 60 * 24);
          console.log(leftDays)
          for(let i=0;i<= leftDays ;i++){
            try {
              const response = await apiServices.post(
                "authentication",
                "/inter/add",
                {
                  Authorization: " Bearer " + token,
                },
                null,
                {
                  purpose: values.purpose,
                  clinicName: values.clinicName,
                  startTime: convertTo24Hour(values.startTime) || "12:00:00",
                  endTime: convertTo24Hour(values.endTime) || "12:00:00",
                  stDate: formatDateToDDMMYY(stdDate),
                  endDate: formatDateToDDMMYY(stdDate),
                }
              );
        
              if (response.statusCode === 200) {
                const updatedScheduleData = response.data;
                dispatch(addSchedule(updatedScheduleData));
                updatedSchedule(updatedScheduleData);
                
              }
            } catch (error) {
              console.error("error is coming ", error);
              if (error.response.data.statusCode === 404) {
                toast.error("Something Went Wrong", {
                  position: "top-center",
                });
              }
            }
            stdDate.setDate(stdDate.getDate() + 1);
            if(i== leftDays){
              reset();
              toggleState("loadingSpinner", false);
            }
          }
  };
  const formik = useFormik({
    initialValues: {
      purpose: "",
      clinicName: "",
      startTime: "12:00:00" || "",
      endTime: "12:00:00" || "",
      stDate: "",
      endDate: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      purpose: Yup.string().required("Purpose is required"),
      clinicName: Yup.string().required("clinicName is required"),
      startTime: Yup.string().required("Starttime is required"),
      endTime: Yup.string().required("endTime is required"),
      stDate: Yup.string().required("startDate is required"),
      endDate: Yup.string().required("endDate is required"),
    }),
    onSubmit: (values) => {
      if(!state.short){
        handleDoctorSchedule(values);
      }else{
        handleshortDaySchedule(values);
      }   
    },
  });

  //for writind apointment time n right format
  useEffect(() => {
    const { startTime, endDate, stDate, endTime } = formik.values;

    if (startTime && endDate && stDate && endTime) {
      const startTimeDate = new Date(`${stDate}T${convertTo24Hour(startTime)}`);
      const endTimeDate = new Date(`${endDate}T${convertTo24Hour(endTime)}`);
    
      // Filter collectScheduleData based on startTime, endTime, stDate, endDate
      const filteredClinics = state.collectScheduleData.some((clinic) => {
        // Assuming clinic.startTime, clinic.endTime are Date objects or can be parsed into Date objects
        const clinicStartTime = new Date(
          `${clinic.stDate}T${clinic.startTime}`
        );
        const clinicEndTime = new Date(`${clinic.endDate}T${clinic.endTime}`);
        return startTimeDate < clinicEndTime && endTimeDate > clinicStartTime;
      });
      toggleState("checkSchedule", filteredClinics);
    }
  }, [
    formik.values.startTime,
    formik.values.endTime,
    formik.values.endDate,
    formik.values.stDate,
  ]);
  useEffect(() => {
    const slots = [];
    const start = new Date(`1970-01-01T00:00:00`);
    const end = new Date(`1970-01-01T23:45:00`);

    while (start <= end) {
      const time = formatTime(start);
      slots.push(time);
      start.setMinutes(start.getMinutes() + 15);
    }
    toggleState("timeSlots", slots);
  }, []);

  return (
    <>
      <div
        id="login-popup"
        tabIndex="-1"
        className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
      >
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
              onClick={reset}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="#c6c7c7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  cliprule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close popup</span>
            </button>
            <div className="p-5">
              {/* <h3 className="text-2xl mb-0.5 font-medium"></h3> */}
              <p className="mb-4 text-sm font-normal text-gray-800"></p>

              <div className="text-center">
                <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
                  Doctor Schedule
                </p>
              </div>

              {/* form */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="expiration-date"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Purpose
                    </label>
                    <input
                      type="text"
                      name="purpose"
                      value={formik.values.purpose}
                      onChange={formik.handleChange}
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                      id="service"
                      placeholder="Purpose"
                    />
                    {formik.touched.purpose && formik.errors.purpose ? (
                      <p style={{ color: "red" }}>{formik.errors.purpose}</p>
                    ) : null}
                  </div>

                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="cvv"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Location
                    </label>
                    <input
                      type="text"
                      id="cvv"
                      name="clinicName"
                      value={formik.values.clinicName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Location"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.clinicName && formik.errors.clinicName ? (
                      <p style={{ color: "red" }}>{formik.errors.clinicName}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1 mb-6">
                    <label
                      for="card-holder"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Start Time
                    </label>
                    <select
                      id="timeSlot"
                      name="startTime"
                      value={formik.values.startTime}
                      onChange={formik.handleChange}
                      className="w-full py-3 px-4 text-black border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    >
                      {state.timeSlots.map((slot, index) => (
                        <option key={index} value={slot}>
                          {slot}
                        </option>
                      ))}
                    </select>
                    {formik.touched.startTime && formik.errors.startTime ? (
                      <p style={{ color: "red" }}>{formik.errors.startTime}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="card-holder"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      End Time
                    </label>
                    <select
                      id="timeSlot"
                      name="endTime"
                      value={formik.values.endTime}
                      onChange={formik.handleChange}
                      className="w-full py-3 px-4 text-black border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    >
                      {state.timeSlots.map((slot, index) => (
                        <option key={index} value={slot}>
                          {slot}
                        </option>
                      ))}
                    </select>
                    {formik.touched.endTime && formik.errors.endTime ? (
                      <p style={{ color: "red" }}>{formik.errors.endTime}</p>
                    ) : null}
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      for="card-number"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      From
                    </label>
                    <input
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                      id="service"
                      type="date"
                      name="stDate"
                      value={formik.values.stDate}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.stDate && formik.errors.stDate ? (
                      <p style={{ color: "red" }}>{formik.errors.stDate}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1 ">
                    <label
                      for="expiration-date"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      To
                    </label>
                    <input
                      type="date"
                      name="endDate"
                      value={formik.values.endDate}
                      onChange={formik.handleChange}
                      id="expiration-date"
                      placeholder="MM / YY"
                      className="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                    />
                    {formik.touched.endDate && formik.errors.endDate ? (
                      <p style={{ color: "red" }}>{formik.errors.endDate}</p>
                    ) : null}
                  </div>
                  <div className="col-span-2 sm:col-span-1 ">
               
                  </div>
                  <div className="col-span-2 sm:col-span-1 pl-10 ">
                  
                    <input
                          type="checkbox"
                          name="modePayment"
                           checked={state.short}
                           onChange={()=>{toggleState("short", !state.short);}}
                          className="form-radio h-4 w-4  text-blue-600"
                          // onChange={(event) => handleVisited(event, patient.id)}
                        />  <span className="tetx-center pl-3 pb-2 text-md">Short Event</span>
                  </div>
                </div>

                {state.checkSchedule === true && (
                  <p style={{ color: "red" }} className="text-center p-2">
                    {" "}
                    Schedule Already Booked on this time
                  </p>
                )}
                <div className="mt-6">
                  <button
                    type="submit"
                    disabled={state.checkSchedule}
                    className={`w-full bg-green-500 hover:bg-blue-600 ${
                      state.checkSchedule ? "opacity-50 cursor-not-allowed" : ""
                    } text-white font-medium py-3 rounded-lg focus:outline-none`}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
      {state.loadingSpinner && <Spinner />}
    </>
  );
};
export default AddScheduleForDoctor;
