import React, { useState } from 'react'

const UseTagInput = (options) => {
    const [inputValue, setInputValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
  
    const handleInputChange = (e) => {
      const value = e.target.value;
      setInputValue(value);
  
      // Filter suggestions
      if (value) {
        const filteredSuggestions = options.filter((option) =>
          option.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestions(filteredSuggestions);
      } else {
        setSuggestions([]);
      }
    };
  
    const addItem = (item) => {
      if (!selectedItems.includes(item)) {
        setSelectedItems([...selectedItems, item]);
      }
      setInputValue("");
      setSuggestions([]);
    };
  
    const removeItem = (item) => {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    };
  
    const handleKeyDown = (e) => {
      if (e.key === "Enter" && inputValue.trim()) {
        addItem(inputValue.trim());
      }
    };
  
    return {
      inputValue,
      suggestions,
      selectedItems,
      handleInputChange,
      handleKeyDown,
      addItem,
      removeItem,
    };
  };
  


export default UseTagInput